import { BorderColor } from "@mui/icons-material";
import { createTheme } from "@mui/material";

const theme = createTheme({
    direction: 'rtl',
    typography: {
        fontFamily: 'IranSans'
    },
    palette: {
        primary: {
            main: 'rgb(15, 97, 164)',
            onMain: 'rgb(238, 238, 238)',
            container: 'rgb(210, 228, 255)',
        },
        success: {
            main: 'rgb(56, 107, 1)',
            container: 'rgb(183, 244, 129)'
        },
        warning: {
            main: 'rgb(255, 226, 82)'
        },
        error: {
            main: 'rgb(186, 26, 26)',
            container: 'rgb(255, 218, 214)'
        },
        black: {
            main: 'rgb(0, 0, 0)'
        },
        background: {
            default: '#eceff1',
        }
    },
    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    borderRadius: '10px'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    fontFamily: "Kalameh",
                    color: 'gray',
                }
            }
        },
        MuiTab: {
            defaultProps: {
                iconPosition: 'start',
            },
            styleOverrides: {
                root: {
                    textAlign: 'start',
                    minHeight: '55px',
                    fontFamily: 'Kalameh',
                    borderRadius: '10px',
                    padding: '0 8px',
                    color: "primary.main",
                }
            }
        }
    }
})

export default theme
import { Avatar, Box, Divider, Paper, Typography } from "@mui/material"
import Button from "../Button/Button"
import { ConsultationConsultantDTO } from "../../../DTOs/consultation/ConsultationConsultantDTO"
import { useNavigate } from "react-router-dom"

interface consultationCardPropTypes {
    consultation: ConsultationConsultantDTO,
    handleCancelButtonClicked?: () => void
    handleSetResultButtonPressed?: () => void,

}


const ConsultantConsultationCard = ({ consultation, handleCancelButtonClicked, handleSetResultButtonPressed }: consultationCardPropTypes) => {

    const navigate = useNavigate();

    const handleJoinClick = () => {
        navigate(`/consultation/${consultation.consultation_type}/${consultation.consultation_code}`)
    }


    return (
        <Paper sx={{ p: 2 }}>
            {consultation.account && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Avatar alt={`${consultation.account.first_name} ${consultation.account.last_name}`}
                            src={consultation.account.image}
                        />
                        <Box>
                            <Typography>{consultation.account.first_name} {consultation.account.last_name}</Typography>
                            <Typography variant="caption">{consultation.account.city}</Typography>
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                        <Typography variant="caption">{consultation.amount} تومان</Typography>
                        <Typography variant="caption">{consultation.specialty.title}</Typography>
                    </Box>
                </Box>
            )}

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box display={'flex'} flexDirection={'column'}>
                <Typography variant={'caption'}>تاریخ درخواست: {consultation.created_at}</Typography>
                <Typography variant={'caption'}>زمان شروع مشاوره: {consultation.start_time}</Typography>
                <Typography variant={'caption'}>تخصص درخواستی: {consultation.specialty.title}</Typography>
                {consultation.consultation_type === 't' && (<Typography variant={'caption'}>نوع مشاوره: مشاوره متنی</Typography>)}
                {consultation.consultation_type === 'vo' && (<Typography variant={'caption'}>نوع مشاوره: مشاوره صوتی</Typography>)}
                {consultation.consultation_type === 'vi' && (<Typography variant={'caption'}>نوع مشاوره: مشاوره تصویری</Typography>)}
            </Box>

            {consultation.can_join && (
                <Button sx={{ mt: 2 }} fullWidth variant={"outlined"} onClick={handleJoinClick}>ورود به صفحه مشاوره</Button>
            )}

            {consultation.status === 'ns' && consultation.can_cancel && (
                <Button sx={{ mt: 2 }} fullWidth variant={"outlined"} onClick={handleCancelButtonClicked}>لغو درخواست</Button>
            )}

            {(consultation.status === 'p' || consultation.status === 'f') && consultation.result === '' && (
                <Button sx={{ mt: 2 }} fullWidth variant={"outlined"} onClick={handleSetResultButtonPressed}>ثبت نتیجه</Button>
            )}

            {consultation.result && (
                <>
                    <Divider style={{ margin: 10 }} />
                    <Typography variant={'caption'}>نتیجه: {consultation.result}</Typography>
                </>
            )}
        </Paper>
    )
}

export default ConsultantConsultationCard;
export class SendVerificationCodeDTO {
    constructor(
        public phone_number: string
    ) {
    }
}

export class SendVerificationCodeDTOResponse {
    constructor(
        public phone_number: string,
        public message: string,
        public status: "ok" | "error"
    ) {
    }
}
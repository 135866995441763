import {SpecialtyDTO} from "../specialty/SpecialtyDTO";
import {AccountSafeDTO} from "../account/AccountSafeDTO";

export enum prescriptionConsultantStatus {
    WAITING = 'w',
    PROCESSING = 'p',
}

export class PrescriptionConsultantDTO {
    constructor(
        public id: number,
        public specialty: SpecialtyDTO,
        public created_at: string,
        public national_code: number,
        public description: string,
        public status: "w" | "p" | "c" | "f",
        public amount: "w" | "p" | "c" | "f",
        public result?: string,
        public account?: AccountSafeDTO,
    ) {}

}
import { Paper } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/reduxHooks";


const BottomNav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoggedIn, isLoading } = useAppSelector((state) => state.account);


    const [value, setValue] = useState<string>(location.pathname);

    const handleChange = (event: any, newValue: string) => {
        setValue(newValue);
        navigate(newValue);
    }
    
    return null;

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { md: 'none' } }} elevation={8}>
            <BottomNavigation showLabels value={value} onChange={handleChange}>
                <BottomNavigationAction value={'/'} label="خانه" icon={<HomeIcon />} />
                <BottomNavigationAction value={'/specialties'} label="تخصص ها" icon={<WorkspacePremiumIcon />} />
                <BottomNavigationAction value={'/consultants'} label="مشاورین" icon={<GroupsIcon />} />
                <BottomNavigationAction disabled={isLoading} value={isLoggedIn? '/account': '/auth'} label="من" icon={<PersonIcon />} />
            </BottomNavigation>
        </Paper>
    )
}

export default BottomNav;
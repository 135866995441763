import { Outlet, useNavigate } from "react-router-dom";
import { BottomNav } from "../components";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useEffect } from "react";


const AuthLayout = () => {
    const { isLoggedIn } = useAppSelector((state) => state.account);
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/", {replace: true})
        }
    }, [isLoggedIn])

    return (
        <>
            <Outlet />

            <BottomNav />
        </>
    )
}

export default AuthLayout;
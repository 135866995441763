import { Avatar, Box, Divider, Paper, Typography } from "@mui/material"
import { Star } from "@mui/icons-material"
import Button from "../Button/Button"
import { ConsultationDTO } from "../../../DTOs/consultation/ConsultationDTO"
import { useNavigate } from "react-router-dom"

interface consultationCardPropTypes {
    consultation: ConsultationDTO,
    handleCancelButtonClicked?: () => void
}

const ConsultationCard = ({ consultation, handleCancelButtonClicked }: consultationCardPropTypes) => {
    
    const navigate = useNavigate();
    
    const handleJoinClick = () => {
        navigate(`/consultation/${consultation.consultation_type}/${consultation.consultation_code}`)
    }

    return (
        <Paper sx={{ p: 2 }}>
            {consultation.consultant && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Avatar alt={`${consultation.consultant.account.first_name} ${consultation.consultant.account.last_name}`}
                            src={consultation.consultant.account.image}
                        />
                        <Box>
                            <Typography>{consultation.consultant.account.first_name} {consultation.consultant.account.last_name}</Typography>
                            <Typography variant="caption">{consultation.specialty.title}</Typography>
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography>{consultation.consultant.rating}</Typography>
                        <Star color='warning' />
                    </Box>
                </Box>
            )}

            <Box textAlign={'center'}>
                {consultation.status === 'c' && (
                    <Typography variant={'caption'} color="error">درخواست شما لغو شده است</Typography>
                )}
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box display={'flex'} flexDirection={'column'}>
                <Typography variant={'caption'}>تاریخ درخواست: {consultation.created_at}</Typography>
                <Typography variant={'caption'}>زمان شروع مشاوره: {consultation.start_time}</Typography>
                <Typography variant={'caption'}>تخصص درخواستی: {consultation.specialty.title}</Typography>
                {consultation.consultation_type === 't' && (<Typography variant={'caption'}>نوع مشاوره: مشاوره متنی</Typography>)}
                {consultation.consultation_type === 'vo' && (<Typography variant={'caption'}>نوع مشاوره: مشاوره صوتی</Typography>)}
                {consultation.consultation_type === 'vi' && (<Typography variant={'caption'}>نوع مشاوره: مشاوره تصویری</Typography>)}
            </Box>

            {consultation.status === 'ns' && (
                <Button sx={{mt: 2}} fullWidth variant={"outlined"} onClick={handleCancelButtonClicked}>لغو درخواست</Button>
            )}
            {consultation.can_join && (
                <Button sx={{mt: 2}} fullWidth variant={"outlined"} onClick={handleJoinClick}>ورود به صفحه مشاوره</Button>
            )}

            {consultation.result && (
                <>
                    <Divider style={{ margin: 10 }} />
                    <Typography variant={'caption'}>نتیجه: {consultation.result}</Typography>
                </>
            )}
        </Paper>
    )
}

export default ConsultationCard;
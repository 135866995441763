import axiosInstance from "../http/axiosConfig";
import {PaginatedDTO} from "../DTOs/PaginatedDTO";
import {TransactionDTO} from "../DTOs/wallet/TransactionDTO";

export const getWalletBalance = () => {
    return axiosInstance.get<{message: string, balance: number, withdrawal_balance: number}>("wallet/")
}

export const getWalletTransactions = (params: any) => {
    return axiosInstance.get<PaginatedDTO<TransactionDTO>>("wallet/transactions/", {params})
}

export const walletTransactionsVerify = () => {
    return axiosInstance.get<null>("wallet/deposit/verify/all")
}

export const createPaymentGateway = (amount: number) => {
    return axiosInstance.post<{amount: number, status: string, payment_gateway: string}>("wallet/deposit/", {amount})
}
import { Avatar, Box, Divider, Pagination, Paper, Rating, TextField, Typography } from "@mui/material";
import ListHeader from "../ListHeader/ListHeader";
import { Add, Star } from "@mui/icons-material";
import { CommentDTO, CreateCommentDTO } from "../../../DTOs/consultant/CommentDTO";
import { PaginatedDTO } from "../../../DTOs/PaginatedDTO";
import { useEffect, useState } from "react";
import { createComment, getConsultantComments } from "../../../services/consultantService";
import { useSearchParams } from "react-router-dom";
import CommentCard from "../Card/CommentCard";
import Button from "../Button/Button";

import * as Yup from 'yup';
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ProtectedBlur from "../ProtectedBlur/ProtectedBlur";
import { useAppSelector } from "../../../hooks/reduxHooks";


interface CommentsSectionProps {
    consultantId: string,
    commentsCount: number,
    rating: number,
}

interface SubmitCommentValues {
    text: string,
    rating: number,
}

const SubmitCommentValidationScheme = Yup.object().shape({
    text: Yup.string().required('این مقدار الزامی است'),
    rating: Yup.number(),
})

const SubmitCommentInitialValues = {
    text: '',
    rating: 3,
}

const CommentsSection = ({ consultantId, commentsCount, rating }: CommentsSectionProps) => {
    const [data, setData] = useState<PaginatedDTO<CommentDTO>>();
    const [searchParams, setSearchParams] = useSearchParams();
    const isLoggedIn = useAppSelector((state) => state.account.isLoggedIn);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: SubmitCommentInitialValues,
        validationSchema: SubmitCommentValidationScheme,
        onSubmit: (values: SubmitCommentValues) => submitComment(values)
    })

    const fetchConsultantComments = async () => {
        const response = await getConsultantComments(consultantId, searchParams);
        setData(response.data);
    }

    const handlePageChange = async (event: any, page: number) => {
        const currentPage = searchParams.get('page') ?? '1';
        if (currentPage === null || currentPage !== page.toString()) {
            setSearchParams((prev) => {
                prev.delete('page')
                prev.append('page', page.toString());
                return prev
            })
            await fetchConsultantComments();
        }
    }

    const getPageCounts = () => {
        const pageCount = (data?.count ?? 0) / 25;

        const pageCountInt = Number.parseInt(pageCount.toString());

        if (pageCountInt === 0) {
            return 1
        }
        if (pageCount > pageCountInt) {
            return pageCountInt + 1
        }
        return pageCountInt
    }

    const submitComment = async (values: SubmitCommentValues) => {
        setLoading(true);
        try {
            const consultantIdInt = Number.parseInt(consultantId);
            const payload = new CreateCommentDTO(values.text, values.rating, consultantIdInt);
            const response = await createComment(payload, consultantIdInt)
            if (response.status == 201) {
                toast.success("نظر شما با موفقیت ثبت شد")
                fetchConsultantComments();
                formik.resetForm();
            }
        } catch (e) {
            toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchConsultantComments();
    }, [])

    return (
        <Paper sx={{ pl: 2, pr: 2, pb: 2 }}>
            <Box>
                <ListHeader title="ثبت نظر" />
                <ProtectedBlur isBlur={isLoggedIn}>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 1 }}>
                            <Rating precision={1} max={5} value={formik.values.rating} name="rating" onChange={formik.handleChange} />
                            <TextField name="text" onChange={formik.handleChange}
                                value={formik.values.text}
                                helperText={formik.touched.text && formik.errors.text}
                                error={formik.touched.text && Boolean(formik.errors.text)}
                                fullWidth variant="outlined" label={'نظر خود را بنویسید'} multiline minRows={3} />
                            <Button loading={loading} variant="contained" type="submit" startIcon={(<Add />)} fullWidth>ثبت نظر</Button>
                        </Box>
                    </form>
                </ProtectedBlur>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <ListHeader title={`نظرات (${commentsCount})`} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="caption">امتیاز: {rating}</Typography>
                    <Star color="warning" />
                </Box>
            </Box>
            {data?.results.map(c => (
                <CommentCard comment={c} />
            ))}

            <Box sx={{ padding: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Pagination color="primary" count={getPageCounts()} defaultPage={Number.parseInt(searchParams.get('page') ?? '1')} size="large" shape="rounded" onChange={handlePageChange} />
            </Box>
        </Paper>
    );
}

export default CommentsSection;
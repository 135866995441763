// import Button as MUIButton from '@mui/material/Button';
import { Button as MUIButton } from '@mui/material';
import { ButtonProps as MUIButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles';


const CustomButton = styled(MUIButton)(({ theme }) => ({
    height: 50,
}))


interface ButtonProps extends MUIButtonProps {
    children: any
}


const Button = ({ children, ...props }: ButtonProps) => {
    return <CustomButton {...props}>{children}</CustomButton>
}


export default Button;
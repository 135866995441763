import {LoginSessionDTO} from "./LoginSessionDTO";

export class RegisterDTO {
    constructor(
        public first_name: string,
        public last_name: string,
        public code: string,
        public phone_number: string,
        public gender: string,
        public birthday: string,
        public city: string,
        public login_session: LoginSessionDTO,
    ) {
    }
}
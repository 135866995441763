import { ArrowBack } from "@mui/icons-material"
import { Box, Paper, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"


interface QuickAccessItemProps {
    title: string,
    img?: any,
    description?: string,
    to?: string,
    onClick?: () => void;
}

const Item = (props: QuickAccessItemProps) => {

    const navigate = useNavigate();

    const handleItemClick = async () => {
        if (props.onClick) {
            props.onClick();
        } else if (props.to) {
            navigate(props.to);
        }
    }
    return (
        <div onClick={handleItemClick}>
            <Paper sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                    <img src={props.img} alt='quick-access' style={{ width: 50, height: 50 }} />
                    <Box>
                        <Typography fontFamily={"Kalameh"}>{props.title}</Typography>
                        {props.description && (
                            <Typography
                                sx={{
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                }}
                                variant="caption">{props.description}</Typography>
                        )}
                    </Box>
                </div>
                <ArrowBack />
            </Paper>
        </div>
    )
}

export default Item;
import { Dialog as MUIDialog, DialogTitle as MUIDialogTitle, DialogContent as MUIDialogContent, DialogActions as MUIDialogActions, Typography, TextField, Divider, Box } from "@mui/material";
import Button from "../Button/Button";
import { PropsWithChildren } from "react";


export interface DialogProps extends PropsWithChildren {
    title: string,
    msg: string,
    open: boolean,
    handleClose: () => void,
    handleAccept: () => void,
    loading?: boolean,
}


const Dialog = ({ title, msg, open, handleClose, handleAccept, loading = false, children }: DialogProps) => {

    const beforeHandleClose = async (event: React.SyntheticEvent, reason: string) => {
        if (reason === "backdropClick" && loading) return; // Prevent closing when clicking outside
        await handleClose();
    }

    return (
        <MUIDialog open={open} onClose={beforeHandleClose} maxWidth="sm" fullWidth disableEscapeKeyDown={loading}>
            <MUIDialogTitle fontFamily={"Kalameh"}>{title}</MUIDialogTitle>
            <MUIDialogContent>
                <Typography variant="body1">{msg}</Typography>
                {children && (
                    <Box sx={{mt: 1, mb: 1}}>
                        {children}
                    </Box>
                )}
            </MUIDialogContent>
            <MUIDialogActions>
                <Button onClick={handleClose} loading={loading} fullWidth color="error" variant="outlined">
                    بستن
                </Button>
                <Button onClick={handleAccept} loading={loading} fullWidth variant="outlined">
                    تایید
                </Button>
            </MUIDialogActions>
        </MUIDialog>
    )
}

export default Dialog;
import { Paper, Typography } from "@mui/material";
import Lottie from "lottie-react";

const Step4 = () => {
    return (
        <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
            <Lottie
                animationData={require('../../../../assets/animation/waiting.json')}
                autoplay
                loop
                style={{ width: '300px', height: '300px' }}
            />

            <Typography sx={{textAlign: 'center'}}>تمامی اطلاعات با موفقیت ارسال شد</Typography>
            <Typography variant="caption" sx={{textAlign: 'center'}}>نتیجه بررسی توسط کارشناسان پس از بررسی به شما
                اطلاع داده خواهد شد</Typography>
        </Paper>
    )
}

export default Step4;
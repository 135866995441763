import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface ProtectedBlurPropTypes extends PropsWithChildren {
    isBlur: boolean,
    title?: string,
}

const ProtectedBlur = ({ children, isBlur, title = 'برای استفاده از این بخش، به حساب کاربری خود وارد شوید' }: ProtectedBlurPropTypes) => {
    return (
        <Box sx={{ position: "relative", width: "100%" }}>
            {!isBlur && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backdropFilter: "blur(10px)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 10,
                        borderRadius: 2,
                    }}
                >
                    <Typography>{title}</Typography>
                </Box>
            )}
            <Box sx={{ filter: isBlur ? "none" : "blur(5px)" }}>{children}</Box>
        </Box>
    );
};

export default ProtectedBlur;

import {SpecialtyDTO} from "../specialty/SpecialtyDTO";

export class ConsultantCreateUpdateDTO {
    constructor(
        public id: number,
        public national_code: string,
        public degree: string,
        public medical_education_number: string,
        public office_address: string,
        public bio: string,
        public specialty: number,
        public is_text_consultation: boolean,
        public is_voice_consultation: boolean,
        public is_video_consultation: boolean,
    ) {
    }

    public toFormData = () => {
        const formData = new FormData();
        formData.append("national_code", this.national_code)
        formData.append("medical_education_number", this.medical_education_number)
        formData.append("office_address", this.office_address)
        formData.append("bio", this.bio)
        formData.append("specialty", this.specialty.toString())
        formData.append("is_text_consultation", this.is_text_consultation.toString())
        formData.append("is_voice_consultation", this.is_voice_consultation.toString())
        formData.append("is_video_consultation", this.is_video_consultation.toString())
        formData.append("degree", this.degree);
        return formData
    }

}
import {SpecialtyDTO} from "../specialty/SpecialtyDTO";
import {ConsultantPrescriptionConsultationDTO} from "../consultant/ConsultantPrescriptionConsultationDTO";

export enum prescriptionStatus {
    WAITING = 'w',
    PROCESSING = 'p',
    CANCELED = 'c',
    FINISHED = 'f',

}

export class PrescriptionDTO {
    constructor(
        public id: number,
        public specialty: SpecialtyDTO,
        public created_at: string,
        public national_code: number,
        public description: string,
        public status: prescriptionStatus,
        public result?: string,
        public consultant?: ConsultantPrescriptionConsultationDTO,
    ) {}

}
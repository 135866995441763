export class UpdateAccountImageDTO {
    constructor(
        public image: string,
    ) {
    }

    public toFormData = () => {
        const formData = new FormData();
        formData.append("image", this.image);
        return formData
    }
}
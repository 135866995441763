import { Container, Grid2 as Grid, MenuItem, TextField, Typography } from "@mui/material";
import { isInteger, useFormik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalaliV3';

import { newDate } from 'date-fns-jalali';

import * as Yup from 'yup';

import Button from "../Button/Button";


import Logo from '../../../assets/images/logo.png';
import { useAppSelector } from "../../../hooks/reduxHooks";



export interface RegisterFormValues {
    firstName: string,
    lastName: string,
    city: string,
    birthday: Date | null,
    gender: string,
}

interface RegisterFormPropsType {
    handleSubmitProp: (values: RegisterFormValues) => void;
    buttonText?: string,
    headerText?: string
    loading?: boolean,
    showLogo?: boolean
}

const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(3, "نام حداقل باید سه حرف باشد").required("این مقدار اجباری است"),
    lastName: Yup.string().min(3, "نام خانوادگی حداقل باید سه حرف باشد").required("این مقدار اجباری است"),
    city: Yup.string().required("این مقدار اجباری است"),
    birthday: Yup.date().required("این مقدار اجباری است"),
    gender: Yup.string().required("این فیلد اجباری است"),
})


const genders = [
    { label: 'آقا', value: "m" },
    { label: 'خانوم', value: "f" },
]


const RegisterForm = ({ handleSubmitProp, buttonText = "ثبت‌نام", headerText = "ثبت‌نام", loading = false, showLogo = true }: RegisterFormPropsType) => {
    const { account } = useAppSelector((state) => state.account);

    const getBirthday = () => {
        if (account) {
            const separatedDate = account.birthday.split('/');
            const year = Number.parseInt(separatedDate[0])
            const month = Number.parseInt(separatedDate[1]) - 1
            const day = Number.parseInt(separatedDate[2]);
            return newDate(year, month, day);
        }
        return null;
    }

    const initialValues = {
        firstName: account ? account.first_name : '',
        lastName: account ? account.last_name : '',
        city: account ? account.city : '',
        birthday: getBirthday(),
        gender: account ? account.gender : ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values: RegisterFormValues) => handleSubmitProp(values),
        validationSchema: validationSchema,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid spacing={1.5} container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid container size={12} sx={{ mb: 3, justifyContent: 'center', alignItems: 'center', display: showLogo? 'flex' : 'none' }}>
                    <img alt={'logo'} src={Logo} className="logo" />
                </Grid>
                <Grid size={{ xs: 12, md: 10 }}>
                    <Typography fontFamily={'Kalameh'}>{headerText}</Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 10 }}>
                    <Typography variant={"caption"}>در صورت وجود هر گونه مشکلی در روند ثبت نام با پشتیبانی تماس بگیرید</Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                    <TextField label='نام' fullWidth name="firstName"
                        value={formik.values.firstName}
                        onBlur={formik.handleBlur}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                        onChange={formik.handleChange}></TextField>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                    <TextField label='نام خانوادگی' fullWidth name="lastName"
                        value={formik.values.lastName}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                        onChange={formik.handleChange}></TextField>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                    <TextField label='شهر' fullWidth name="city"
                        value={formik.values.city}
                        onBlur={formik.handleBlur}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                        onChange={formik.handleChange}></TextField>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFnsJalali} dateFormats={{ monthShort: 'MMMM' }}>
                        <DatePicker label={"تاریخ تولد"} views={['year', 'month', 'day']}
                            value={formik.values.birthday}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    helperText: formik.touched.birthday && formik.errors.birthday,
                                    error: formik.touched.birthday && Boolean(formik.errors.birthday),
                                    onBlur: formik.handleBlur,
                                },
                            }}
                            name="birthday"
                            onChange={(newValue) => formik.setFieldValue('birthday', newValue)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                    <TextField label='جنسیت' fullWidth name="gender"
                        value={formik.values.gender}
                        onBlur={formik.handleBlur}
                        error={formik.touched.gender && Boolean(formik.errors.gender)}
                        helperText={formik.touched.gender && formik.errors.gender}
                        onChange={formik.handleChange} select>
                        {genders.map((gender) => (
                            <MenuItem key={gender.value} value={gender.value}>
                                {gender.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                    <Button fullWidth variant={'contained'} type="submit" loading={loading} disabled={!formik.isValid}>
                        {buttonText}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}


export default RegisterForm;
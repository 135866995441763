import {configureStore} from "@reduxjs/toolkit";
import AccountReducer from "../features/account/accountSlice";

export const store = configureStore({
    reducer: {
        account: AccountReducer,
    }
})


export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { Container, Grid2 as Grid, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Step2, { ConsultantAdditionalInformationFormValues } from "./consultantRegisterSteps/Step2";
import { UpdateAccountImageDTO } from "../../../DTOs/account/updateAccountImageDTO";
import { updateAccountImage } from "../../../services/accountService";
import { ConsultantCreateUpdateDTO } from "../../../DTOs/consultant/ConsultantCreateUpdateDTO";
import { toast } from "react-toastify";
import { createTimeLine, deleteAllTimeLine, registerConsultant, updateConsultantInfo } from "../../../services/consultantService";
import { useAppSelector } from "../../../hooks/reduxHooks";
import Step3 from "./consultantRegisterSteps/Step3";
import { TimeLineDTO } from "../../../DTOs/consultant/TimeLineDTO";
import Step4 from "./consultantRegisterSteps/Step4";

const steps = [
    { label: 'ثبت نام' },
    { label: 'اطلاعات تکمیلی' },
    { label: 'زمان‌بندی' },
    { label: 'درانتظار تایید' },
]

const RegisterConsultantRoute = () => {
    const { account } = useAppSelector((state) => state.account);
    const [currentStep, setCurrentStep] = useState(2);
    const [loading, setLoading] = useState(false);

    const handleStep2Submit = async (values: ConsultantAdditionalInformationFormValues) => {
        setLoading(true);
        try {
            const updateAccountImageDTO = new UpdateAccountImageDTO(values.consultantImage);
            await updateAccountImage(updateAccountImageDTO)
        } catch (error) {

        }

        try {
            const payload = new ConsultantCreateUpdateDTO(1, values.nationalCode, values.consultantDegreeImage, values.medicalEducationNumber,
                values.officeAddress, values.bio, values.specialty!!, values.isTextConsultation, values.isVoiceConsultation, values.isVideoConsultation
            )
            if (shouldEdit()) {
                await updateConsultantInfo(payload);
            } else {
                await registerConsultant(payload);
            }
            nextStep();
        } catch (e: any) {
            if (e.response.data.account) {
                toast.error(e.response.data.account[0]);
            } else if (e.response.data.national_code) {
                toast.error(e.response.data.national_code[0]);
            } else if (e.response.data.medical_education_number) {
                toast.error(e.response.data.medical_education_number[0]);
            }
        } finally {
            setLoading(false)
        }

    }

    const handleStep3Submit = async (timelines: TimeLineDTO[]) => {
        setLoading(true)
        try {
            const result = await deleteAllTimeLine();
            if (result.status === 204) {
                for (const timeLine of timelines) {
                    await createTimeLine(timeLine);
                }
            }
            nextStep();
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    const shouldEdit = (): boolean => {
        return (account !== undefined && account.role === 'consultant')
    }

    const nextStep = () => {
        if (currentStep < 4) {
            setCurrentStep((prev) => prev + 1);
        }
    }

    const showStepContent = () => {
        switch (currentStep) {
            case 2:
                return <Step2 handleSubmitProps={handleStep2Submit} loading={loading} loadData={shouldEdit()} />
            case 3:
                return <Step3 loading={loading} loadData={shouldEdit()} handleSubmitProp={handleStep3Submit} />
            case 4:
                return <Step4 />
        }
    }

    useEffect(() => {
        if (account?.authorize_status === 'unauthorized' && account.need_to_edit === false && account.role === 'consultant') {
            setCurrentStep(4);
        }
    }, [])

    return (
        <Container sx={{ pt: 1 }}>
            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid size={{ xs: 12 }}>
                    <Typography fontFamily={'Kalameh'}>ثبت نام به عنوان مشاور</Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Typography variant={"caption"}>در صورت وجود هر گونه مشکلی در روند ثبت نام با پشتیبانی تماس بگیرید</Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 4, lg: 3 }}>
                    <Paper sx={{ p: 3, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                        <Stepper activeStep={currentStep - 1} orientation="vertical">
                            {steps.map(s => (
                                <Step key={s.label}>
                                    <StepLabel sx={{ fontFamily: 'Kalameh' }}>{s.label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Paper>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 8, xl: 9 }}>
                    {showStepContent()}
                </Grid>
            </Grid>
        </Container>
    )
}

export default RegisterConsultantRoute;
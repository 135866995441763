export class LoginSessionDTO {
    constructor(
        public platform?: string,
        public token?: string,
        public expire_time?: Date,
        public id?: number,
        public ip_address?: string,
        public logged_in_session?: boolean,
    ) {
    }
}

import { Container, Grid2 as Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { browserName, osName } from "react-device-detect";

import * as Yup from 'yup';

import Logo from '../../../assets/images/logo.png';
import { Button } from "../../components";
import { sendVerificationCode, verifyPhoneNumber } from "../../../services/authService";
import { SendVerificationCodeDTO } from "../../../DTOs/auth/SendVerificationCodeDTO";
import { VerifyPhoneNumberDTO } from "../../../DTOs/auth/VerifyPhoneNumberDTO";
import { LoginSessionDTO } from "../../../DTOs/auth/LoginSessionDTO";
import { saveToken } from "../../../services/tokenStorageService";
import { toast } from "react-toastify";
import { getAccountInfo, saveAccountInfoActionCreator } from "../../../services/accountService";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

interface FormValues {
    phoneNumber: string;
    verificationCode: string;
    editingPhoneNumber: boolean,
}

const validationSchema = Yup.object({
    phoneNumber: Yup.string().max(11, "شماره تلفن باید 11 رقم باشد").min(11, "شماره تلفن باید 11 رقم باشد").required('فیلد شماره تلفن اجباری است'),
    editingPhoneNumber: Yup.boolean().notRequired(),
    verificationCode: Yup.string().max(4, "کد تایید باید 4 رقم باشد").when('editingPhoneNumber', {
        is: (editingPhoneNumber: boolean | undefined) => editingPhoneNumber === false,
        then: (schema) => schema.required("لطفا کد تایید را وارد کنید"),
        otherwise: (schema) => schema.notRequired(),
    })
});


const initialValues = {
    phoneNumber: '',
    verificationCode: '',
    editingPhoneNumber: true
}



const AuthRoute = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sendAgainVerificationCodeSecond, setSendAgainVerificationCodeSecond] = useState(120);
    const {state} = useLocation();


    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values: FormValues) => onFormSubmit(values),
        validationSchema: validationSchema,
    })

    const onFormSubmit = async (values: FormValues) => {
        if (values.editingPhoneNumber) {
            await handleSendVerificationCode();
        } else {
            await handleVerifyPhoneNumber(values);
        }
    }

    const handleVerifyPhoneNumber = async (values: FormValues) => {
        setLoading(true);

        try {
            const payload = new VerifyPhoneNumberDTO(values.phoneNumber, values.verificationCode, new LoginSessionDTO(`${browserName} ${osName}`))
            const response = await verifyPhoneNumber(payload)
            // save token if user have account already
            if (response.data.is_have_account) {
                saveToken(response.data.login_session?.token!!);
                const { data } = await getAccountInfo();
                dispatch(saveAccountInfoActionCreator(data));
                toast.success("خوش آمدید")

                const {successRedirect} = state;
                
                navigate(successRedirect ?? '/');
            } else {
                const {phoneNumber, verificationCode} = values
                navigate("/auth/register", {state: {phoneNumber, verificationCode}})
            }
        } catch (e) {
            toast.error("کد ارسال شده منقضی یا اشتباه است")
        } finally {
            setLoading(false);
        }
    }

    const handleSendVerificationCode = async () => {
        const { phoneNumber } = formik.values;
        setLoading(true);
        try {
            await sendVerificationCode(new SendVerificationCodeDTO(phoneNumber))
            formik.setFieldValue('editingPhoneNumber', false);
            setSendAgainVerificationCodeSecond(120);
            toast.success("کد تایید برای شما ارسال شد")
        } catch (e) {
            toast.error("در ارتباط با سرور مشکلی پیش آمده لطفا دقایقی دیگر مجدد تلاش کنید");
        } finally {
            setLoading(false);
        }
    }

    const handleEditButtonClick = () => {
        formik.setFieldValue('editingPhoneNumber', true);
        formik.setFieldValue("verificationCode", "");
    }

    const convertSecondToMinute = () => {
        const completeMinute: number = Number.parseInt((sendAgainVerificationCodeSecond / 60).toString(), 0)
        const seconds = sendAgainVerificationCodeSecond % 60
        return `${completeMinute}:${seconds}`;
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSendAgainVerificationCodeSecond((prevState) => prevState - 1);
        }, 1000)
        return () => clearInterval(intervalId)
    }, [])

    return (
        <Container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh'}}>
            <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>
            <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }} direction={'column'} spacing={1.5}>
                <Grid container size={{ xs: 12, md: 5 }} sx={{ mb: 3, justifyContent: 'center', alignItems: 'center' }}>
                    <img alt={'logo'} src={Logo} className="logo" />
                </Grid>
                <Grid size={{ xs: 12, md: 5 }} sx={{ mb: 1 }}>
                    <Typography fontFamily={'Kalameh'}>ورود | ثبت‌نام</Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }} sx={{ mb: 1 }}>
                    {formik.values.editingPhoneNumber ? (
                        <Typography variant="caption">لطفا شماره تلفن خود را وارد کنید</Typography>
                    ) : (
                        <Typography variant="caption">اصطلاح شماره تلفن (<Button onClick={handleEditButtonClick}>ویرایش</Button>)</Typography>
                    )}
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                    <TextField placeholder="09123456789" label='شماره تلفن' fullWidth name="phoneNumber"
                        value={formik.values.phoneNumber}
                        onBlur={formik.handleBlur}
                        type="tel"
                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                        disabled={!formik.values.editingPhoneNumber}
                        onChange={formik.handleChange}></TextField>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }} sx={{ display: formik.values.editingPhoneNumber ? 'none' : 'flex' }}>
                    <TextField placeholder="-  -  -  -" label='کد فعالسازی' fullWidth name="verificationCode"
                        value={formik.values.verificationCode}
                        onBlur={formik.handleBlur}
                        type="tel"
                        error={formik.touched.verificationCode && Boolean(formik.errors.verificationCode)}
                        helperText={formik.touched.verificationCode && formik.errors.verificationCode}
                        onChange={formik.handleChange}></TextField>
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                    <Button fullWidth variant={'contained'} type="submit" loading={loading} disabled={!formik.isValid}>
                        {formik.values.editingPhoneNumber ? "ارسال کد فعالسازی" : "ورود به حساب کاربری"}
                    </Button>
                </Grid>
                {(sendAgainVerificationCodeSecond > 0 && !formik.values.editingPhoneNumber) && (

                    <Grid size={{ xs: 12, md: 5 }}>
                        <Typography variant="caption">ارسال مجدد کد در {convertSecondToMinute()}</Typography>
                    </Grid>
                )}

                {(sendAgainVerificationCodeSecond <= 0 && !formik.values.editingPhoneNumber) && (
                    <Grid size={{ xs: 12, md: 5 }}>
                        <Button fullWidth variant={'outlined'} onClick={handleSendVerificationCode}>ارسال مجدد کد فعالسازی</Button>
                    </Grid>
                )}
            </Grid>
        </form>
        </Container>
    )
}

export default AuthRoute;
import axiosInstance from "../http/axiosConfig";
import {AccountDTO} from "../DTOs/account/accountDTO";
import {UpdateAccountImageDTO} from "../DTOs/account/updateAccountImageDTO";
import {UpdateAccountDTO} from "../DTOs/account/updateAccountDTO";
import {setAccountInformation} from "../features/account/accountSlice";

export const getAccountInfo = () => {
    return axiosInstance.get<AccountDTO>("/account/");
}

export const updateAccountInfo = (updateAccountDTO: UpdateAccountDTO) => {
    return axiosInstance.patch<AccountDTO>("/account/", updateAccountDTO);
}

export const updateAccountImage = (updateAccountImageDTO: UpdateAccountImageDTO) => {
    return axiosInstance.patch<AccountDTO>("/account/", updateAccountImageDTO.toFormData(), {
        headers: {"Content-Type": "multipart/form-data"}
    });
}

export const saveAccountInfoActionCreator = (account: AccountDTO) => {
    return setAccountInformation({
        account: new AccountDTO(account.id, account.first_name, account.last_name,
            account.phone_number, account.birthday,
            account.role, account.authorize_status, account.need_to_edit, account.city, account.image, account.gender)
    })
}
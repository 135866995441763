import {AccountSafeDTO} from "../account/AccountSafeDTO";

export class CommentDTO {
    constructor(
        public id: number,
        public account: AccountSafeDTO,
        public created_at: string,
        public text: string,
        public rating: number,
        public consultant: number
    ) {

    }
}

export class CreateCommentDTO {
    constructor(
        public text: string,
        public rating: number,
        public consultant: number
    ) {
    }
}
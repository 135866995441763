import { useFormik } from "formik"
import * as Yup from 'yup';
import { TimeLineDTO } from "../../../../DTOs/consultant/TimeLineDTO";
import { useEffect, useState } from "react";
import { getTimeLines } from "../../../../services/consultantService";
import { Grid2 as Grid, IconButton, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalaliV3';
import { Add, Delete } from "@mui/icons-material";
import { Button } from "../../../components";


interface Step3PropTypes {
    loading: boolean,
    loadData: boolean,
    handleSubmitProp: (timeLines: TimeLineDTO[]) => void,
}

const days = [
    { title: "شنبه", value: "sa" },
    { title: "یک‌شنبه", value: "su" },
    { title: "دو‌شنبه", value: "m" },
    { title: "سه‌شنبه", value: "tu" },
    { title: "چهار‌شنبه", value: "w" },
    { title: "پنج‌شنبه", value: "th" },
    { title: "جمعه", value: "f" },
]

const durations = [
    { title: '15 دقیقه', value: '15' },
    { title: '30 دقیقه', value: '30' },
    { title: '45 دقیقه', value: '45' },
    { title: '60 دقیقه', value: '60' },
]

const timelineValidation = Yup.object().shape({
    day: Yup.string().required(),
    timeFrom: Yup.string().required(),
    timeTo: Yup.string().required(),
    duration: Yup.string().required(),
})

interface ConsultantTimeLineFormValues {
    day?: "sa" | "su" | "m" | "tu" | "w" | "th" | "f",
    timeFrom?: string,
    timeTo?: string,
    duration?: "15" | "30" | "45" | "60",
}

const initialValues = { day: undefined, timeFrom: undefined, timeTo: undefined, duration: undefined }

const Step3 = (props: Step3PropTypes) => {
    const [timeLines, setTimeLines] = useState<TimeLineDTO[]>([]);


    const formik = useFormik({
        initialValues,
        onSubmit: (values: ConsultantTimeLineFormValues) => appendTimeLine(values),
        validationSchema: timelineValidation
    })

    const appendTimeLine = (submittedValues: ConsultantTimeLineFormValues) => {
        const currentTimeLines = [...timeLines];
        console.log(submittedValues);

        const newTimeLine = new TimeLineDTO(undefined, submittedValues.day!!, submittedValues.timeFrom!!,
            submittedValues.timeTo!!, submittedValues.duration!!);
        currentTimeLines.push(newTimeLine);
        setTimeLines(currentTimeLines);
    }

    const deleteTimeLine = (id: number) => {
        setTimeLines((prevState) => prevState.filter(timeline => timeline.id !== id))
    }

    useEffect(() => {
        if (props.loadData) {
            const fetchTimeLines = async () => {
                const result = await getTimeLines();
                const newTimelines: TimeLineDTO[] = []
                for (const timeline of result.data.results) {
                    newTimelines.push(new TimeLineDTO(timeline.id, timeline.day, timeline.time_from.slice(0, 5), timeline.time_to.slice(0, 5), timeline.duration));
                }

                setTimeLines(newTimelines)
            }
            fetchTimeLines();
        }
    }, []);

    return (
        <>
            <Paper sx={{ p: 3 }}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <TextField label={"روز"} fullWidth select name="day" value={formik.values.day}
                                error={!formik.isValid}
                                onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                {days.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFnsJalali} >
                                <TimePicker label={"از ساعت"}
                                ampm={false}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            onBlur: formik.handleBlur,
                                            error: !formik.isValid,
                                        },
                                    }}
                                    name="timeFrom"
                                    onChange={(newValue: any) => formik.setFieldValue('timeFrom', `${newValue.getHours().toString().padStart(2, '0')}:${newValue.getMinutes().toString().padStart(2, '0')}`)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFnsJalali} >
                                <TimePicker label={"تا ساعت"}
                                ampm={false}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            onBlur: formik.handleBlur,
                                            error: !formik.isValid
                                        },
                                    }}
                                    name="timeTo"
                                    onChange={(newValue: any) => formik.setFieldValue('timeTo', `${newValue.getHours().toString().padStart(2, '0')}:${newValue.getMinutes().toString().padStart(2, '0')}`)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <TextField label={"زمان"} fullWidth select name="duration" value={formik.values.duration}

                                error={!formik.isValid}
                                onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                {durations.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid size={12}>
                            <Button fullWidth variant="outlined" startIcon={(<Add />)} onClick={formik.submitForm}>افزودن</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            <Paper sx={{ p: 3, mt: 1 }}>
                <Grid container spacing={1}>
                    {timeLines.map(t => (
                        <Grid size={12} sx={{ display: 'flex', justifyContent: 'space-around', textAlign: 'center', alignItems: 'center', bgcolor: 'background.default', borderRadius: '10px', p: 1 }}>
                            <Typography>{t.getDay()}</Typography>
                            <Typography>|</Typography>
                            <Typography>از {t.time_from}</Typography>
                            <Typography>|</Typography>
                            <Typography>تا {t.time_to}</Typography>
                            <Typography>|</Typography>
                            <Typography>{t.duration} دقیقه</Typography>
                            <Typography>|</Typography>
                            <IconButton color="error" onClick={() => deleteTimeLine(t.id!!)}><Delete /></IconButton>
                        </Grid>
                    ))}
                    <Grid size={12}>
                        <Button fullWidth variant="contained" onClick={() => props.handleSubmitProp(timeLines)} loading={props.loading}>ثبت نهایی</Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Step3;
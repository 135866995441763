import { Container} from "@mui/material";
import { useEffect, useState } from "react";

import { RegisterForm } from "../../components";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RegisterDTO } from "../../../DTOs/auth/RegisterDTO";
import { browserName, osName } from "react-device-detect";
import { LoginSessionDTO } from "../../../DTOs/auth/LoginSessionDTO";
import { register } from "../../../services/authService";
import { saveToken } from "../../../services/tokenStorageService";
import { saveAccountInfoActionCreator } from "../../../services/accountService";
import { RegisterFormValues } from "../../components/RegisterForm/RegisterForm";
import { format } from "date-fns-jalali";
import { toast } from "react-toastify";



const RegisterRoute = () => {
    const [loading, setLoading] = useState(false);
    const { state } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();


    useEffect(() => {
        // visit this route after verifying phoneNumber
        if (!state || !state.phoneNumber || !state.verificationCode) {
            navigate('/auth', { replace: true })
        }
    }, [])


    const onFormSubmit = async (values: RegisterFormValues) => {
        setLoading(true);
        const { phoneNumber, verificationCode } = state;
        const birthday = format(values.birthday!!, "yyyy-MM-dd");
        

        const payload = new RegisterDTO(values.firstName, values.lastName, verificationCode, phoneNumber, values.gender, birthday,
            values.city, new LoginSessionDTO(`${browserName} ${osName}`));

            try {
                const response = await register(payload)
                saveToken(response.data.login_session?.token!!);
                dispatch(saveAccountInfoActionCreator(response.data))
                
                const {successRedirect} = state;
                navigate(successRedirect ?? '/', {replace: true});
            } catch (e) {
                toast.error("در فرایند ثبت‌نام مشکلی به وجود آمده. لطفا با پشتیبانی تماس بگیرید");
            } finally {
                setLoading(false);
            }

    }

    return (
        <Container sx={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <RegisterForm handleSubmitProp={onFormSubmit} loading={loading} />
        </Container >
    )
}

export default RegisterRoute;
import { useEffect, useRef, useState } from "react";
import { getToken } from "../../../services/tokenStorageService";
import { Box, Container, Grid2 as Grid, IconButton, Paper, Typography } from "@mui/material";
import { Button, Dialog } from "../../components";
import { Call, LocalSee, Mic, MicOff, NoPhotography, PhoneDisabled, VideocamOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";


const SIGNALING_SERVER_URL = process.env.REACT_APP_WS_URL;

type SignalingMessage = {
    type: "offer" | "answer" | "ice-candidate" | 'data' | 'close_consultation' | 'close_consultation_request';
    offer?: RTCSessionDescriptionInit;
    answer?: RTCSessionDescriptionInit;
    candidate?: RTCIceCandidateInit;
};

const VideoChatRoute = () => {
    const { consultationCode } = useParams();
    const localVideoRef = useRef<HTMLVideoElement>(null);
    const remoteVideoRef = useRef<HTMLVideoElement>(null);
    const peerRef = useRef<RTCPeerConnection | null>(null);
    const wsRef = useRef<WebSocket | null>(null);

    const [localStream, setLocalStream] = useState<MediaStream | null>(null);


    const [isConnected, setIsConnected] = useState(false);
    const [isOfferCreated, setIsOfferCreated] = useState(false);
    const [micOn, setMicOn] = useState(true);
    const [cameraOn, setCameraOn] = useState(true);


    const navigate = useNavigate()

    const [showCloseConsultationDialog, setShowCloseConsultationDialog] = useState(false);



    useEffect(() => {
        wsRef.current = new WebSocket(`${SIGNALING_SERVER_URL}signaling/${consultationCode}/?token=${getToken()}`);
        wsRef.current.onopen = () => console.log("Connected to signaling server");
        wsRef.current.onmessage = handleSignalingMessage;

        navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((stream) => {
                if (localVideoRef.current) localVideoRef.current.srcObject = stream;
                initPeerConnection(stream);
                setLocalStream(stream);
            });


        return () => {
            localStream?.getTracks().forEach((track) => track.stop());
            wsRef.current?.close()
            wsRef.current = null;
            peerRef.current?.close();
            peerRef.current = null;
        };
    }, []);

    const initPeerConnection = (stream: MediaStream) => {
        peerRef.current = new RTCPeerConnection({
            iceServers: [
                { urls: 'stun:turn.doctoreman.com.com:3478' },
                { urls: 'turn:turn.doctoreman.com:3478?transport=udp', username: 'turnuser', credential: 'turn456' }
            ]
        });
        stream.getTracks().forEach((track) => peerRef.current?.addTrack(track, stream));

        peerRef.current.onicecandidate = (event) => {
            if (event.candidate && wsRef.current) {
                wsRef.current.send(JSON.stringify({ type: "ice-candidate", candidate: event.candidate }));
            }
        };

        peerRef.current.ontrack = (event) => {
            if (remoteVideoRef.current) remoteVideoRef.current.srcObject = event.streams[0];
        };
    };

    const handleSignalingMessage = (message: MessageEvent) => {
        const data: SignalingMessage = JSON.parse(message.data);
        switch (data.type) {
            case "offer":
                handleOffer(data.offer!);
                break;
            case "answer":
                peerRef.current?.setRemoteDescription(new RTCSessionDescription(data.answer!));
                break;
            case "ice-candidate":
                peerRef.current?.addIceCandidate(new RTCIceCandidate(data.candidate!));
                break;
            case "close_consultation_request":
                setShowCloseConsultationDialog(true);
                break;

            case "close_consultation":
                toast.info("اتمام جلسه");
                navigate(-1);
                break;
            default:
                break;
        }
    };

    const startCall = async () => {
        if (!peerRef.current) return;
        const offer = await peerRef.current.createOffer();
        await peerRef.current.setLocalDescription(offer);
        wsRef.current?.send(JSON.stringify({ type: "offer", offer }));
        setIsOfferCreated(true);
    };

    const handleOffer = async (offer: RTCSessionDescriptionInit) => {
        if (!peerRef.current) return;
        await peerRef.current.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await peerRef.current.createAnswer();
        await peerRef.current.setLocalDescription(answer);
        wsRef.current?.send(JSON.stringify({ type: "answer", answer }));
        setIsConnected(true);
    };


    const closeConsultationMessage = () => {
        if (wsRef.current) {
            const newMessage = { message_type: 'closeConsultationRequest' }
            wsRef.current.send(JSON.stringify(newMessage));
            toast.success("درخواست شما برای طرف مقابل ارسال شد")
        }
    }

    const closeConsultation = () => {
        if (wsRef.current) {
            const newMessage = { message_type: 'closeConsultation' }
            wsRef.current.send(JSON.stringify(newMessage));
            toast.info("اتمام جلسه")
            navigate(-1);
        }
    }

    const toggleMute = () => {
        if (localStream) {
            localStream.getAudioTracks().forEach((track) => {
                track.enabled = !track.enabled;
                setMicOn(track.enabled)
            });
        }
    }

    const toggleCamera = () => {
        if (localStream) {
            localStream.getVideoTracks().forEach((track) => {
                track.enabled = !track.enabled;
                setCameraOn(track.enabled)
            });
        }
    }

    return (
        <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100vh', pb: 1, pt: 1 }}>
                <Box sx={{ flexGrow: 1, justifyContent: 'center', display: 'flex' }}>
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <video ref={localVideoRef} autoPlay playsInline muted style={{ borderRadius: '10px', width: '100%', height: '100%' }}></video>
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <video ref={remoteVideoRef} autoPlay playsInline style={{ borderRadius: '10px', width: '100%', height: '100%' }}></video>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Paper sx={{ p: 3, display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                        {isOfferCreated ? (
                            <Button onClick={closeConsultationMessage}
                                startIcon={(<PhoneDisabled />)}
                                color="error"
                                variant="contained">اتمام گفتگو</Button>
                        ) : (
                            <Button onClick={startCall}
                                startIcon={(<Call />)}
                                variant="contained">شروع گفتگو</Button>
                        )}

                        <Button onClick={toggleMute}
                            variant="contained">
                            {micOn ? (<Mic />) : (<MicOff />)}
                        </Button>
                        <Button onClick={toggleCamera}
                            variant="contained">
                            {cameraOn ? (<LocalSee />) : (<NoPhotography />)}
                        </Button>
                    </Paper>
                </Box>
            </Box>


            <Dialog open={showCloseConsultationDialog} title="اتمام جلسه" msg="طرف مقابل درخواست اتمام جلسه را دارد"
                handleAccept={closeConsultation}
                handleClose={() => setShowCloseConsultationDialog(false)} />
        </Container>
    );
}


export default VideoChatRoute;
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxHooks";
import { useEffect } from "react";
import { Typography } from "@mui/material";

interface ProtectedRouteProps {
    redirectPath?: string,
    successRedirect?: string,
    accountRole?: 'user' | 'consultant',
}


const ProtectedRoute = ({ redirectPath = "/auth", accountRole = 'user', successRedirect = '/' }: ProtectedRouteProps) => {
    const navigate = useNavigate()
    const { isLoggedIn, isLoading, account } = useAppSelector((state) => state.account);

    useEffect(() => {
        if (!isLoading && !isLoggedIn) {
            navigate(redirectPath, { replace: true, state: {successRedirect}})
        } else if (!isLoading && !isLoggedIn && account?.role !== accountRole) {
            navigate(redirectPath, { replace: true, state: {successRedirect} })
        }
    }, [isLoggedIn, isLoading, account]);

    return isLoading ? <Typography variant="h4" textAlign={'center'}>در حال انتقال...</Typography> : <Outlet />;
};

export default ProtectedRoute;

import {LoginSessionDTO} from "../auth/LoginSessionDTO";

export class AccountDTO {
    constructor(
        public id: number,
        public first_name: string,
        public last_name: string,
        public phone_number: string,
        public birthday: string,
        public role: 'user' | 'consultant',
        public authorize_status: 'authorized' | 'unauthorized',
        public need_to_edit: boolean,
        public city: string,
        public image: string,
        public gender: "m" | 'f',
        public login_session?: LoginSessionDTO
    ) {
    }
}
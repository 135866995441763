import { Avatar, Grid2 as Grid, Paper, Typography } from "@mui/material";

import StarIcon from '@mui/icons-material/Star';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PlaceIcon from '@mui/icons-material/Place';
import Button from "../Button/Button";
import { ConsultantDetailDTO } from "../../../DTOs/consultant/ConsultantDetailDTO";
import { Link } from "react-router-dom";

interface ConsultantCardPropTypes {
    consultant: ConsultantDetailDTO
}

const ConsultantCard = ({ consultant }: ConsultantCardPropTypes) => {
    return (
        <Paper>
            <Grid container justifyContent={"space-between"} sx={{ p: 2 }}>
                <Grid direction={'row'} container spacing={1}>
                    <StarIcon color="warning" />
                    <Typography>{consultant.rating}</Typography>
                </Grid>
                <Grid textAlign={'center'}>
                    <Avatar sx={{ width: 56, height: 56, display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={consultant.account.image} />
                    <Typography fontFamily={"Kalameh"}>{consultant.account.first_name} {consultant.account.last_name}</Typography>
                    <Typography>{consultant.specialty.title}</Typography>
                    <Grid direction={'row'} justifyContent={"center"} container>
                        <PlaceIcon />
                        <Typography>{consultant.account.city}</Typography>
                    </Grid>
                </Grid>
                <Grid direction={'row'} container spacing={1}>
                    <Typography>{consultant.comments_count}</Typography>
                    <ChatBubbleIcon />
                </Grid>
            </Grid>
            <Link to={`/consultants/${consultant.id}`}>
                <Button fullWidth variant="contained">
                    جزئیات بیشتر
                </Button>
            </Link>
        </Paper>
    )
}

export default ConsultantCard;
import { ArrowCircleDown, ArrowCircleUp } from "@mui/icons-material";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { TransactionDTO } from "../../../DTOs/wallet/TransactionDTO";

interface TransactionCardProps {
    transaction: TransactionDTO
}

const TransactionCard = (props: TransactionCardProps) => {
    return (
        <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    {props.transaction.transaction_type === 'd' ? (
                        <>
                            <ArrowCircleDown color="success" />
                            <Typography>واریز {props.transaction.amount} تومان</Typography>
                        </>
                    ) : (
                        <>
                            <ArrowCircleUp color="error" />
                            <Typography>برداشت {props.transaction.amount} تومان</Typography>
                        </>
                    )}
                </Box>
                <Typography variant="caption">{props.transaction.created_at.toString()}</Typography>
            </Box>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {props.transaction.status === 'w' &&
                    <Typography variant="subtitle2" color="warning">وضعیت: درانتظار تایید</Typography>
                }
                {props.transaction.status === 's' &&
                    <Typography variant="subtitle2" color="success">وضعیت: موفقیت آمیز</Typography>
                }
                {props.transaction.status === 'e' &&
                    <Typography variant="subtitle2" color="error">وضعیت: با خظار مواجه شد</Typography>
                }
                <Typography variant="subtitle2">توضیحات: {props.transaction.description}</Typography>
            </Box>
        </Paper>
    );
}

export default TransactionCard;
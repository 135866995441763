import { cloneElement, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Divider, Drawer, Icon, IconButton, List, ListItem, ListItemText, Typography, useMediaQuery, useScrollTrigger, useTheme } from '@mui/material';
import { Link, Navigate, NavLink, useNavigate } from 'react-router-dom';


import Logo from '../../../assets/images/logo.png';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Menu } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import Button from '../Button/Button';


interface ToolbarProps {
    isLoggedIn: boolean,
    isLoading: boolean
}


const pages = [
    { title: 'صفحه اصلی', to: '/', icon: <HomeIcon /> },
    { title: 'تخصص ها', to: '/specialties', icon: <GroupsIcon /> },
    { title: 'مشاورین', to: '/consultants', icon: <WorkspacePremiumIcon /> },
    { title: 'ثبت نام مشاور', to: '/membership', icon: <PersonIcon /> },
];

const WideToolbar = (props: ToolbarProps) => {
    const activeStyle = { fontWeight: 'bold', color: 'primary.main', borderBottom: 1, borderRadius: 0 };
    const deactivateStyle = { color: 'black.main' }

    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
                <img src={Logo} className='logo' alt={'logo'} />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                    <NavLink to={page.to} key={page.to}>
                        {({ isPending, isActive }) => (
                            <Button sx={isActive ? activeStyle : deactivateStyle} key={page.to}>
                                {page.title}
                            </Button>
                        )}

                    </NavLink>
                ))}
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                {props.isLoggedIn ? (
                    <Link to={'/account'}>
                        <Button
                            sx={{ background: 'none', border: 1, height: 40 }}
                            startIcon={(<PersonIcon />)}
                            variant={'text'}>حساب کاربری</Button></Link>
                ) : (
                    <Link to={'/auth'}>
                        <Button
                            loading={props.isLoading}
                            sx={{ background: 'none', border: 1, height: 40 }}
                            startIcon={(<LoginIcon />)}
                            variant={'text'}>ورود / ثبت نام</Button></Link>
                )}
            </Box>
        </>
    )
}

const MobileToolbar = (props: any) => {
    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', justifyContent: 'space-between' } }}>
                <img src={Logo} className='logo' alt={'logo'} />
                <IconButton onClick={props.toggleDrawer(true)}>
                    <Menu />
                </IconButton>
            </Box>
        </>
    )
}


const Header = () => {
    const { isLoggedIn, isLoading, account } = useAppSelector((state) => state.account);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const ElevationScroll = (props: any) => {
        const { children } = props;

        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
        });

        return cloneElement(children, {
            elevation: trigger ? 4 : 0
        });
    }

    const toggleDrawer = (open: any) => (event: any) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpen(open);
    };

    return (
        <>
            <ElevationScroll>
                <AppBar position={'sticky'} color={'inherit'} sx={{borderRadius: '0'}}>
                    <Container>
                        <Toolbar>
                            {isMobile ? (
                                <MobileToolbar toggleDrawer={toggleDrawer} />
                            ) : (
                                <WideToolbar isLoggedIn={isLoggedIn} isLoading={isLoading} />
                            )}
                        </Toolbar>
                    </Container>
                </AppBar>
            </ElevationScroll>
            <Drawer open={open} anchor='left' onClose={toggleDrawer(false)}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 1, width: 300, }}>
                    <Box>
                        {isLoggedIn ? (
                            <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Avatar src={account?.image} alt="profile" sx={{ width: '85px', height: '85px' }} />
                                <Typography fontFamily={'Kalameh'}>{account?.first_name} {account?.last_name} </Typography>
                                <Typography>{account?.phone_number} </Typography>
                                <Link to={'/account'}>
                                    <Button
                                        fullWidth
                                        sx={{ background: 'none', border: 1 }}
                                        startIcon={(<PersonIcon />)}
                                        variant={'text'}>حساب کاربری</Button></Link>
                            </Box>
                        ) : (
                            <Link to={'/auth'}>
                                <Button
                                    fullWidth
                                    loading={isLoading}
                                    sx={{ background: 'none', border: 1 }}
                                    startIcon={(<LoginIcon />)}
                                    variant={'text'}>ورود / ثبت نام</Button></Link>
                        )}
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                            {pages.map((p) => (
                                <div key={p.title}>
                                    <Button startIcon={p.icon} sx={{ textAlign: 'start' }} fullWidth onClick={() => {
                                        navigate(p.to);
                                    }}>{p.title}</Button>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                </div>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </>
    )
}

export default Header;
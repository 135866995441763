import {ConsultantPrescriptionConsultationDTO} from "../consultant/ConsultantPrescriptionConsultationDTO";
import {SpecialtyDTO} from "../specialty/SpecialtyDTO";


export enum consultationStatus {
    PROCESSING = 'p',
    CANCELED = 'c',
    FINISHED = 'f',
    NOT_STARTED = 'ns'
}



export class ConsultationDTO {
    constructor(
        public id: number,
        public created_at: string,
        public consultation_type: "t" | "vo" | "vi",
        public status: consultationStatus,
        public start_time: string,
        public consultation_code: string,
        public consultant: ConsultantPrescriptionConsultationDTO,
        public specialty: SpecialtyDTO,
        public can_join: boolean,
        public result?: string
    ) {

    }

}
import { Outlet, useLocation } from "react-router-dom";
import { BottomNav, Footer, Header } from "../components";
import { Box } from "@mui/material";

const MainLayout = () => {
    const { pathname } = useLocation();

    const boxSx = () => {
        return pathname === '/'? {pb: 3} : {pb: 3, pt: 1}
    }

    return (
        <>
            <Header />

            <Box sx={boxSx()}>
                <Outlet />
            </Box>

            <BottomNav />

            <Footer />
        </>
    )
}

export default MainLayout;
import { useFormik } from "formik";
import Dialog from "./Dialog";
import * as Yup from 'yup';
import { Autocomplete, Box, MenuItem, TextField, Typography } from "@mui/material";
import Button from "../Button/Button";
import { useEffect, useRef, useState } from "react";
import { checkDiscounts } from "../../../services/discountService";
import { toast } from "react-toastify";
import { SpecialtyDTO } from "../../../DTOs/specialty/SpecialtyDTO";
import { getSpecialties } from "../../../services/specialtyService";
import { PrescriptionCreateDTO } from "../../../DTOs/prescriptions/PrescriptionCreateDTO";
import { createPrescription } from "../../../services/prescriptionService";
import { ConsultationCreateDTO } from "../../../DTOs/consultation/ConsultationCreateDTO";
import { cancelConsultation, createConsultation } from "../../../services/consultationService";
import Lottie from "lottie-react";
import { getToken } from "../../../services/tokenStorageService";
import { useNavigate } from "react-router-dom";

interface UrgentConsultationDialogProps {
    open: boolean,
    handleClose: () => void,
}

interface UrgentConsultationRequestValues {
    specialty: string,
    discountCode: string,
    consultationType: string
}

const UrgentConsultationValidation = Yup.object().shape({
    consultationType: Yup.string().required("لطفا نوع مشاوره درخواستی خود را وارد کنید"),
    specialty: Yup.number().required('این مقدار الزامی است').typeError('لطفا مقدار عددی وارد کنید'),
    discountCode: Yup.string().optional()
})

const initialValues = {
    specialty: '',
    discountCode: '',
    consultationType: '',
}

const ALERTING_SERVER_URL = process.env.REACT_APP_WS_URL;

const UrgentConsultationDialog = ({ open, handleClose }: UrgentConsultationDialogProps) => {
    const [price, setPrice] = useState(120);
    const [loadingCheckDiscount, setLoadingCheckDiscount] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [specialties, setSpecialties] = useState<SpecialtyDTO[]>([]);
    const [consultationData, setConsultationData] = useState<{consultationId: number, consultationType: string, consultationCode: string}>();

    const wsRef = useRef<WebSocket | null>(null);

    const navigate = useNavigate();


    const [waiting, setWaiting] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema: UrgentConsultationValidation,
        onSubmit: (values: UrgentConsultationRequestValues) => submitRequest(values),
    })

    const getSpecialtiesAsync = async () => {
        const response = await getSpecialties();
        setSpecialties(response.data.results);
    }

    const handleCheckDiscountCode = async () => {
        if (formik.values.discountCode) {
            setLoadingCheckDiscount(true);
            setPrice(120);
            try {
                const result = await checkDiscounts(formik.values.discountCode);
                if (result.data.valid) {
                    setPrice((100 - result.data.percentage) * price / 100)
                    toast.success("کد تخفیف شما اعمال شد");
                } else {
                    toast.error("کد تخیفیف نامعتبر است");
                }
            } catch (error) {
                toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
            } finally {
                setLoadingCheckDiscount(false);
            }
        }
    }

    const submitRequest = async (values: UrgentConsultationRequestValues) => {
        if (!waiting) {
            setLoadingSubmit(true);
            try {
                const payload = new ConsultationCreateDTO(undefined, values.consultationType, values.specialty, values.discountCode, undefined);
                const { data } = await createConsultation(payload);
                
                setConsultationData({consultationId: data.id, consultationType: data.consultation_type, consultationCode: data.consultation_code});

                toast.success("درخواست شما با موفقیت ثبت شد");
                setWaiting(true);
                formik.resetForm();
                wsRef.current?.send(JSON.stringify({
                    msg_type: 'alerting',
                    consultation_id: data.id
                }))
            } catch (e: any) {
                if (e.response.data && e.response.data.balance) {
                    toast.error(e.response.data.balance);
                } else if (e.response.data && e.response.data.start_time) {
                    toast.error(e.response.data.start_time[0]);
                }

            } finally {
                setLoadingSubmit(false);
            }
        }

    }

    const handlePreClose = async () => {
        setWaiting(false);
        if (consultationData?.consultationId) {
            await cancelConsultation(consultationData?.consultationId!!);
            handleClose();
        } else {
            handleClose();
        }

    }

    const handleJoin = async () => {
        navigate(`/consultation/${consultationData?.consultationType}/${consultationData?.consultationCode}`)
        handleClose();
    }

    useEffect(() => {
        getSpecialtiesAsync();

        wsRef.current = new WebSocket(`${ALERTING_SERVER_URL}alerting/user/?token=${getToken()}`);

        wsRef.current.onmessage = event => {
            const data = JSON.parse(event.data);
            console.log(consultationData);
            if (data.msg_type == 'accepting') {
                handleJoin();
            }
        }

        return () => {
            wsRef.current?.close();
            wsRef.current = null;
        }

    }, [consultationData])
    return (
        <Dialog open={open} title="مشاوره‌ی فوری" msg={waiting ? 'در انتظار تایید مشاور' : 'لطفا همه مقادیر را وارد کنید'} loading={loadingSubmit} handleAccept={formik.submitForm} handleClose={handlePreClose}>
            {waiting ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Lottie
                        animationData={require('../../../assets/animation/waiting.json')}
                        autoplay
                        loop
                        style={{ width: '300px', height: '300px' }}
                    />

                    <Typography sx={{ textAlign: 'center' }}>درخواست شما به مشاورین ارسال شد</Typography>
                    <Typography variant="caption" sx={{ textAlign: 'center' }}>پس از تایید درخواست شما به صورت خودکار به صفحه ی مشاوره منتقل خواهید شد</Typography>
                </Box>
            ) : (
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <TextField label='نوع مشاوره' select
                                name="consultationType"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.consultationType && Boolean(formik.errors.consultationType)}
                                helperText={formik.touched.consultationType && formik.errors.consultationType}
                                fullWidth>
                                <MenuItem key={'t'} value={'t'}>مشاوره متنی</MenuItem>
                                <MenuItem key={'vo'} value={'vo'}>مشاوره صوتی</MenuItem>
                                <MenuItem key={'vi'} value={'vi'}>مشاوره تصویری</MenuItem>
                            </TextField>
                            <Autocomplete
                                options={specialties}
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValues) => formik.setFieldValue('specialty', newValues?.id)}
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                    <TextField label={'تخصص'} {...params}
                                        error={formik.touched.specialty && Boolean(formik.errors.specialty)}
                                        helperText={formik.touched.specialty && formik.errors.specialty} />
                                )}
                            />
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <TextField label="کد تخفیف"
                                    name="discountCode"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    sx={{ flex: 1 }} />
                                <Button variant="outlined" onClick={handleCheckDiscountCode} loading={loadingCheckDiscount}>اعمال کن</Button>
                            </Box>
                        </Box>
                    </form>

                    <Typography variant="caption">توجه: هزینه مشاوره‌ی فوری <Typography variant="caption" color="primary">{price} هزار تومان
                    </Typography> می‌باشد از کافی بودن موجودی کیف پول خود اطمینان حاصل کنید</Typography>
                    <br />

                    <Typography variant={'caption'}>در صورت لغو درخواست توسط شما یا مشاور مبلغ پرداختی به کیف پول شما عودت داده
                        می‌شود</Typography>
                </>
            )}
        </Dialog>
    )
}

export default UrgentConsultationDialog;
import axiosInstance from "../http/axiosConfig";
import {ConsultationCreateDTO, ConsultationCreateResponseDTO} from "../DTOs/consultation/ConsultationCreateDTO";
import {PaginatedDTO} from "../DTOs/PaginatedDTO";
import {ConsultationDTO, consultationStatus} from "../DTOs/consultation/ConsultationDTO";
import {ConsultationConsultantDTO} from "../DTOs/consultation/ConsultationConsultantDTO";
import { ConsultationSetResultDTO } from "../DTOs/consultation/ConsultationResultDTO";

export const createConsultation = (consultationCreateDTO: ConsultationCreateDTO) => {
    return axiosInstance.post<ConsultationCreateResponseDTO>("consultations/", consultationCreateDTO)
}

export const getConsultations = (params: any) => {
    return axiosInstance.get<PaginatedDTO<ConsultationDTO>>("consultations/", {params})
}

export const getConsultationsForConsultant = (params: any) => {
    return axiosInstance.get<PaginatedDTO<ConsultationConsultantDTO>>("consultations/consultant/", {params})
}

export const cancelConsultation = (consultationsId: number) => {
    return axiosInstance.get<{status: string}>(`consultations/${consultationsId}/cancel/`)
}

export const setResultConsultation = (consultationsId: number, consultationSetResultDTO: ConsultationSetResultDTO) => {
    return axiosInstance.put<{status: string}>(`consultations/${consultationsId}/result/`, consultationSetResultDTO)
}


export const uploadFile = (formData: FormData) => {
    return axiosInstance.post<{file_url: string}>(`consultations/upload/`, formData, {
        headers: {"Content-Type": "multipart/form-data"}
    })
}
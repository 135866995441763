import { useFormik } from "formik";
import Dialog from "./Dialog";
import * as Yup from 'yup';
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import Button from "../Button/Button";
import { useEffect, useState } from "react";
import { checkDiscounts } from "../../../services/discountService";
import { toast } from "react-toastify";
import { SpecialtyDTO } from "../../../DTOs/specialty/SpecialtyDTO";
import { getSpecialties } from "../../../services/specialtyService";
import { PrescriptionCreateDTO } from "../../../DTOs/prescriptions/PrescriptionCreateDTO";
import { createPrescription } from "../../../services/prescriptionService";

interface SubmitPrescriptionDialogProps {
    open: boolean,
    handleClose: () => void,
}

interface SubmitPrescriptionRequestValues {
    nationalCode: string,
    specialty: string,
    description: string,
    discountCode: string,
}

const SubmitPrescriptionValidation = Yup.object().shape({
    nationalCode: Yup.number().required('این مقدار الزامی است').typeError('کد ملی را صحیح وارد کنید'),
    description: Yup.string().required('این مقدار الزامی است'),
    specialty: Yup.number().required('این مقدار الزامی است').typeError('لطفا مقدار عددی وارد کنید'),
    discountCode: Yup.string().optional()
})

const initialValues = {
    nationalCode: '',
    specialty: '',
    description: '',
    discountCode: '',

}

const SubmitPrescriptionDialog = ({ open, handleClose }: SubmitPrescriptionDialogProps) => {
    const [price, setPrice] = useState(120);
    const [loadingCheckDiscount, setLoadingCheckDiscount] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [specialties, setSpecialties] = useState<SpecialtyDTO[]>([]);

    const formik = useFormik({
        initialValues,
        validationSchema: SubmitPrescriptionValidation,
        onSubmit: (values: SubmitPrescriptionRequestValues) => submitRequest(values),
    })

    const getSpecialtiesAsync = async () => {
        const response = await getSpecialties();
        setSpecialties(response.data.results);
    }

    const handleCheckDiscountCode = async () => {
        if (formik.values.discountCode) {
            setLoadingCheckDiscount(true);
            setPrice(120);
            try {
                const result = await checkDiscounts(formik.values.discountCode);
                if (result.data.valid) {
                    setPrice((100 - result.data.percentage) * price / 100)
                    toast.success("کد تخفیف شما اعمال شد");
                } else {
                    toast.error("کد تخیفیف نامعتبر است");

                }
            } catch (error) {
                toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
            } finally {
                setLoadingCheckDiscount(false);
            }
        }
    }

    const submitRequest = async (values: SubmitPrescriptionRequestValues) => {
        setLoadingSubmit(true);
        try {
            const payload = new PrescriptionCreateDTO(values.nationalCode, values.description, values.specialty, values.discountCode);
            await createPrescription(payload);
            toast.success("درخواست شما با موفقیت ثبت شد");
            handleClose();
        } catch (e: any) {
            if (e.response.data && e.response.data.balance) {
                toast.error(e.response.data.balance);
            }
        } finally {
            setLoadingSubmit(false);

        }

    }

    useEffect(() => {
        getSpecialtiesAsync();
    }, [])
    return (
        <Dialog open={open} title="ثبت نسخه" msg="لطفا همه مقادیر را وارد کنید" loading={loadingSubmit} handleAccept={formik.submitForm} handleClose={handleClose}>

            <form onSubmit={formik.handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <TextField fullWidth
                        name="nationalCode"
                        helperText={formik.touched.nationalCode && formik.errors.nationalCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.nationalCode && Boolean(formik.errors.nationalCode)}
                        label="کد ملی" />
                    <Autocomplete
                        options={specialties}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, newValues) => formik.setFieldValue('specialty', newValues?.id)}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                            <TextField label={'تخصص'} {...params}
                                error={formik.touched.specialty && Boolean(formik.errors.specialty)}
                                helperText={formik.touched.specialty && formik.errors.specialty} />
                        )}
                    />
                    <TextField fullWidth
                        name="description"
                        helperText={formik.touched.description && formik.errors.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        label="توضیحات" multiline minRows={3} />

                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <TextField label="کد تخفیف"
                            name="discountCode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{ flex: 1 }} />
                        <Button variant="outlined" onClick={handleCheckDiscountCode} loading={loadingCheckDiscount}>اعمال کن</Button>
                    </Box>
                </Box>
            </form>

            <Typography variant="caption">توجه: هزینه ثبت درخواست نسخه <Typography variant="caption" color="primary">{price} هزار تومان
            </Typography> می‌باشد از کافی بودن موجودی کیف پول خود اطمینان حاصل کنید</Typography>
            <br />

            <Typography variant={'caption'}>در صورت لغو درخواست توسط شما یا مشاور مبلغ پرداختی به کیف پول شما عودت داده
                می‌شود</Typography>
        </Dialog>
    )
}

export default SubmitPrescriptionDialog;
import {PrescriptionDTO, prescriptionStatus} from "../DTOs/prescriptions/PrescriptionDTO";
import axiosInstance from "../http/axiosConfig";
import {PaginatedDTO} from "../DTOs/PaginatedDTO";
import {PrescriptionCreateDTO} from "../DTOs/prescriptions/PrescriptionCreateDTO";
import {PrescriptionConsultantDTO} from "../DTOs/prescriptions/PrescriptionConsultantDTO";
import {PrescriptionSetResultDTO} from "../DTOs/prescriptions/PrescriptionSetResultDTO";

export const getPrescriptions = (params: any) => {
    return axiosInstance.get<PaginatedDTO<PrescriptionDTO>>("prescriptions/", {params})
}
export const getWaitingPrescriptionsForConsultant = (params: any) => {
    return axiosInstance.get<PaginatedDTO<PrescriptionConsultantDTO>>("prescriptions/waiting/", {params})
}
export const getProcessingPrescriptionsForConsultant = (params: any) => {
    return axiosInstance.get<PaginatedDTO<PrescriptionConsultantDTO>>("prescriptions/processing/", {params})
}

export const acceptPrescriptionsForConsultant = (prescriptionId: number) => {
    return axiosInstance.get<{status: string}>(`prescriptions/${prescriptionId}/accept/`)
}

export const createPrescription = (prescriptionCreateDTO: PrescriptionCreateDTO) => {
    return axiosInstance.post<PaginatedDTO<PrescriptionDTO>>("prescriptions/", prescriptionCreateDTO)
}
export const setResultPrescription = (prescriptionId: number, prescriptionSetResultDTO: PrescriptionSetResultDTO) => {
    return axiosInstance.put<{status: string}>(`prescriptions/${prescriptionId}/result/`, prescriptionSetResultDTO)
}
export const cancelPrescriptionByUser = (prescriptionId: number) => {
    return axiosInstance.get<{status: string}>(`prescriptions/${prescriptionId}/cancel/`)
}
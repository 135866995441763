import axiosInstance from "../http/axiosConfig";
import {PaginatedDTO} from "../DTOs/PaginatedDTO";
import {DiscountDTO} from "../DTOs/discounts/DiscountDTO";

export const getDiscounts = (params: any) => {
    return axiosInstance.get<PaginatedDTO<DiscountDTO>>("discounts/", {params});
}

export const checkDiscounts = (code: string) => {
    return axiosInstance.get<{valid: boolean, percentage: number}>(`discounts/check/${code}/`);
}
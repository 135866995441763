import {LoginSessionDTO} from "./LoginSessionDTO";

export class VerifyPhoneNumberDTO {
    constructor(
        public phone_number: string,
        public code: string,
        public login_session: LoginSessionDTO
    ) {
    }
}

export class VerifyPhoneNumberDTOResponse {
    constructor(
        public phone_number: string,
        public code: string,
        public message: string,
        public status: "ok" | "error",
        public is_have_account: boolean,
        public login_session?: LoginSessionDTO,
    ) {
    }
}
import { Avatar, Box, Divider, Paper, Typography } from "@mui/material"
import { PrescriptionDTO } from "../../../DTOs/prescriptions/PrescriptionDTO"
import { Star } from "@mui/icons-material"
import Button from "../Button/Button"

interface PrescriptionCardPropTypes {
    prescription: PrescriptionDTO,
    handleCancelButtonClicked?: () => void
}

const PrescriptionCard = ({ prescription, handleCancelButtonClicked }: PrescriptionCardPropTypes) => {
    return (
        <Paper sx={{ p: 2 }}>
            {prescription.consultant && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Avatar alt={`${prescription.consultant.account.first_name} ${prescription.consultant.account.last_name}`}
                            src={prescription.consultant.account.image}
                        />
                        <Box>
                            <Typography>{prescription.consultant.account.first_name} {prescription.consultant.account.last_name}</Typography>
                            <Typography variant="caption">{prescription.specialty.title}</Typography>
                        </Box>
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography>{prescription.consultant.rating}</Typography>
                        <Star color='warning' />
                    </Box>
                </Box>
            )}

            <Box textAlign={'center'}>

                {!prescription.consultant && prescription.status === 'w' && (
                    <Typography variant={'caption'} color='error'>درخواست شما توسط مشاوری هنوز
                        تایید نشده است</Typography>
                )}

                {prescription.status === 'c' && (
                    <Typography variant={'caption'} color='error'>درخواست شما لغو شده است</Typography>
                )}

                {prescription.status === 'p' && (
                    <Typography variant={'caption'} color='error'>درخواست شما توسط {prescription.consultant?.account.first_name} {prescription.consultant?.account.last_name} در حال پردازش است</Typography>
                )}
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant={'caption'}>کد ملی ارسال شده: {prescription.national_code}</Typography>
                <Typography variant={'caption'}>تاریخ درخواست: {prescription.created_at}</Typography>
                <Typography variant={'caption'}>توضیحات: {prescription.description}</Typography>
            </Box>


            {prescription.status === 'w' && (
                <Button sx={{ mt: 2 }} fullWidth variant="outlined" onClick={handleCancelButtonClicked}>لغو درخواست</Button>
            )}


            {prescription.result && (
                <>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Typography variant={'caption'}>نتیجه: {prescription.result}</Typography>
                </>
            )}
        </Paper>
    )
}

export default PrescriptionCard;

import { Box, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { CacheProvider } from "@emotion/react";

import theme from "./ui/themes/theme";
import rtlCache from "./ui/cache/rtlCache";

import MainLayout from './ui/layouts/MainLayout';
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "./ui/layouts/AuthLayout";
import {
    ActiveSessionsRoute, AuthRoute, ConsultantConsultationsRoute, ConsultantDetailRoute,
    ConsultantPrescriptionsRoute, ConsultantsRoute, ConsultationsRoute, DiscountsRoute, EditAccountInfoRoute,
    PrescriptionsRoute, RegisterConsultantRoute, RegisterRoute, SpecialtiesRoute, VideoChatRoute, VoiceChatRoute, WalletRoute
} from "./ui/routes";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";

import { AppDispatch } from "./app/store";
import { useEffect, useRef, useState } from "react";
import { fetchAccountData } from "./features/account/accountSlice";
import HomeRoute from "./ui/routes/HomeRoute";
import ProtectedRoute from "./guard/ProtectedRoute";
import AccountLayout from "./ui/layouts/AccountLayout";
import { Dialog, Slider } from "./ui/components";
import { SwiperSlide } from "swiper/react";
import { AdDTO } from "./DTOs/ad/AdDTO";
import { getAds } from "./services/adService";
import { getToken } from "./services/tokenStorageService";
import { ConsultationConsultantDTO } from "./DTOs/consultation/ConsultationConsultantDTO";

const ALERTING_SERVER_URL = process.env.REACT_APP_WS_URL;

function App() {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [ads, setAds] = useState<AdDTO[]>();
    const wsRef = useRef<WebSocket | null>(null);
    const [urgentConsultation, setUrgentConsultation] = useState<ConsultationConsultantDTO | null>(null);



    useEffect(() => {
        dispatch(fetchAccountData()); // Fetch account data on the first load
    }, [dispatch]);


    const getConsultationType = () => {
        let result = ""
        switch (urgentConsultation?.consultation_type) {
            case "t":
                result = "مشاوره متنی"
                break;
            case "vo":
                result = "مشاوره صوتی"
                break;
            case "vi":
                result = "مشاوره تصویری"
                break;
        }
        return result;
    }

    const joinToConsultation = () => {
        wsRef.current?.send(JSON.stringify({ 'msg_type': 'accepting', 'consultation_id': urgentConsultation?.id }));
        setUrgentConsultation(null);
        navigate(`/consultation/${urgentConsultation?.consultation_type}/${urgentConsultation?.consultation_code}`)
    }

    useEffect(() => {
        wsRef.current = new WebSocket(`${ALERTING_SERVER_URL}alerting/consultant/?token=${getToken()}`);

        wsRef.current.onmessage = event => {
            const data = JSON.parse(event.data);
            setUrgentConsultation(data);
        }

        const fetchAd = async () => {
            const response = await getAds({ ad_position: 't' });
            setAds(response.data);
        }

        fetchAd();

    }, [])

    return (
        <CacheProvider value={rtlCache}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {!pathname.startsWith('/consultation') && (
                    <Slider sxPerView={1} mdPerView={1} xlPerView={1} autoplay>
                        {ads?.map(a => (
                            <SwiperSlide>
                                <Link to={a.link} target="_blank">
                                    <img
                                        src={a.banner}
                                        alt={a.title}
                                        height={60}
                                        style={{ width: "100%", objectFit: 'cover' }}
                                    />
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Slider>
                )}
                <Routes>
                    <Route element={<MainLayout />}>
                        <Route index element={(<HomeRoute />)} />
                        <Route path="specialties" element={(<SpecialtiesRoute />)} />
                        <Route path="consultants" element={(<ConsultantsRoute />)} />
                        <Route path="consultants/:consultantId" element={(<ConsultantDetailRoute />)} />
                    </Route>

                    <Route path={"auth"} element={<AuthLayout />}>
                        <Route index element={<AuthRoute />} />
                        <Route path="register" element={<RegisterRoute />} />
                    </Route>

                    <Route path={'membership'} element={<ProtectedRoute successRedirect="/membership" />}>
                        <Route element={(<MainLayout />)}>
                            <Route index element={<RegisterConsultantRoute />} />
                        </Route>
                    </Route>

                    <Route element={(<ProtectedRoute />)}>
                        <Route path="/account" element={<AccountLayout />}>
                            <Route index element={<EditAccountInfoRoute />} />
                            <Route path="wallet" element={<WalletRoute />} />
                            <Route path="consultations" element={<ConsultationsRoute />} />
                            <Route path="prescriptions" element={<PrescriptionsRoute />} />
                            <Route path="discounts" element={<DiscountsRoute />} />
                            <Route path="active-sessions" element={<ActiveSessionsRoute />} />
                        </Route>
                    </Route>

                    <Route element={(<ProtectedRoute accountRole="consultant" redirectPath="/" />)}>
                        <Route path="/consultant" element={<AccountLayout />}>
                            <Route path="consultations" element={<ConsultantConsultationsRoute />} />
                            <Route path="prescriptions" element={<ConsultantPrescriptionsRoute />} />
                        </Route>
                    </Route>

                    <Route path="consultation/vi/:consultationCode" element={<VideoChatRoute />} />
                    <Route path="consultation/vo/:consultationCode" element={<VoiceChatRoute />} />
                </Routes>
                <ToastContainer draggable closeOnClick position={'top-center'} closeButton={false} />

                <Dialog open={urgentConsultation !== null} handleClose={() => setUrgentConsultation(null)} handleAccept={joinToConsultation}
                    title="درخواست مشاوره‌ی فوری"
                    msg={``}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, }}>

                        <Typography variant={'caption'}>درخواست
                            کننده: {urgentConsultation?.account.first_name} {urgentConsultation?.account.last_name}</Typography>
                        <Typography variant={'caption'}>نوع مشاوره: {getConsultationType()}</Typography>
                        <Typography variant={'caption'}>تخصص درخواستی: {urgentConsultation?.specialty.title}</Typography>
                        <Typography variant={'caption'}>مبلغ دریافتی: {urgentConsultation?.amount} تومان</Typography>
                    </Box>
                </Dialog>
            </ThemeProvider>
        </CacheProvider>
    );
}

export default App;

import {SendVerificationCodeDTO, SendVerificationCodeDTOResponse} from "../DTOs/auth/SendVerificationCodeDTO";
import axiosInstance from "../http/axiosConfig";
import {VerifyPhoneNumberDTO, VerifyPhoneNumberDTOResponse} from "../DTOs/auth/VerifyPhoneNumberDTO";
import {RegisterDTO} from "../DTOs/auth/RegisterDTO";
import {AccountDTO} from "../DTOs/account/accountDTO";


export const sendVerificationCode = (verificationCodeDto: SendVerificationCodeDTO) => {
    return axiosInstance.post<SendVerificationCodeDTOResponse>("account/send-verification-code/", verificationCodeDto)
}

export const verifyPhoneNumber = (verifyPhoneNumberDTO: VerifyPhoneNumberDTO) => {
    return axiosInstance.post<VerifyPhoneNumberDTOResponse>("account/verify/", verifyPhoneNumberDTO);
}


export const register = (registerDTO: RegisterDTO) => {
    return axiosInstance.post<AccountDTO>("account/register/", registerDTO);
}


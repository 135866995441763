import {ConsultantDTO} from "../DTOs/consultant/ConsultantDTO";
import axiosInstance from "../http/axiosConfig";
import {TimeLineDTO} from "../DTOs/consultant/TimeLineDTO";
import {PaginatedDTO} from "../DTOs/PaginatedDTO";
import {ConsultantCreateUpdateDTO} from "../DTOs/consultant/ConsultantCreateUpdateDTO";
import {ConsultantDetailDTO} from "../DTOs/consultant/ConsultantDetailDTO";
import {TimelinesDTO} from "../DTOs/consultant/TimelinesDTO";
import {CommentDTO, CreateCommentDTO} from "../DTOs/consultant/CommentDTO";

export const registerConsultant = (consultantDTO: ConsultantCreateUpdateDTO) => {
    return axiosInstance.post<ConsultantCreateUpdateDTO>("/account/consultant/register/", consultantDTO.toFormData(),
        {
            headers: {"Content-Type": 'multipart/form-data'}
        }
    )
}

export const getConsultantInfo = () => {
    return axiosInstance.get<ConsultantDTO>("/account/consultant/")
}

export const getConsultantDetail = (consultantId: string) => {
    return axiosInstance.get<ConsultantDetailDTO>(`/consultants/${consultantId}`)
}

export const getConsultantTimelines = (consultantId: number) => {
    return axiosInstance.get<TimelinesDTO>(`/consultants/${consultantId}/timelines/`)
}

export const getConsultantComments = (consultantId: string, params: any) => {
    return axiosInstance.get<PaginatedDTO<CommentDTO>>(`/consultants/${consultantId}/comments/`, {params})
}

export const createComment = (createCommentDTO: CreateCommentDTO, consultantId: number) => {
    return axiosInstance.post(`/consultants/${consultantId}/comments/`, createCommentDTO);
}

export const updateConsultantInfo = (consultantDTO: ConsultantCreateUpdateDTO) => {
    return axiosInstance.patch<ConsultantCreateUpdateDTO>("/account/consultant/", consultantDTO.toFormData(),
        {
            headers: {"Content-Type": 'multipart/form-data'}
        }
    )
}

export const updateConsultantOnlineStatus = (is_online: boolean)=> {
    return axiosInstance.patch<ConsultantCreateUpdateDTO>("/account/consultant/", {is_online})
}

export const consultantSearch = (limit?: number, params?: any) => {
    return axiosInstance.get<PaginatedDTO<ConsultantDetailDTO>>("/consultants", {params})
}

export const getTimeLines = () => {
    return axiosInstance.get<PaginatedDTO<TimeLineDTO>>("/account/consultant/timelines/")
}

export const createTimeLine = (timeLineDTO: TimeLineDTO) => {
    return axiosInstance.post<TimeLineDTO>("/account/consultant/timelines/", timeLineDTO)
}

export const deleteAllTimeLine = () => {
    return axiosInstance.delete("/account/consultant/timelines/delete-all/");
}
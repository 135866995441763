import { useEffect, useState } from "react";
import { PrescriptionDTO, prescriptionStatus } from "../../../DTOs/prescriptions/PrescriptionDTO";
import { PaginatedDTO } from "../../../DTOs/PaginatedDTO";
import { Grid2 as Grid, Pagination, Paper, Tab, Tabs } from "@mui/material";
import { AccessTime, HighlightOff, TaskAlt, Update } from "@mui/icons-material";
import { cancelPrescriptionByUser, getPrescriptions } from "../../../services/prescriptionService";
import { useSearchParams } from "react-router-dom";
import { Dialog, PrescriptionCard } from "../../components";
import { toast } from "react-toastify";

const PrescriptionsRoute = () => {
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams({ 'status': 'w' });
    const [data, setData] = useState<PaginatedDTO<PrescriptionDTO>>();
    const [status, setStatus] = useState<prescriptionStatus | string>(searchParams.get('status')!!);
    const [loading, setLoading] = useState(false);
    const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(0);




    const handleChange = async (event: React.SyntheticEvent, newValue: prescriptionStatus) => {
        setStatus(newValue);
        const currentStatus = searchParams.get('status') ?? 'w';
        if (currentStatus === null || currentStatus !== newValue) {
            setSearchParams((prev) => {
                prev.delete('status')
                prev.append('status', newValue);
                prev.delete('page')
                prev.append('page', '1');
                return prev
            })
            await fetchData();
        }
    };

    const fetchData = async () => {
        const response = await getPrescriptions(searchParams);
        setData(response.data);
    }

    const handlePageChange = async (event: any, page: number) => {
        const currentPage = searchParams.get('page') ?? '1';
        if (currentPage === null || currentPage !== page.toString()) {
            setSearchParams((prev) => {
                prev.delete('page')
                prev.append('page', page.toString());
                return prev
            })
            await fetchData();
        }
    }

    const getPageCounts = () => {
        const pageCount = (data?.count ?? 0) / 25;

        const pageCountInt = Number.parseInt(pageCount.toString());

        if (pageCountInt === 0) {
            return 1
        }
        if (pageCount > pageCountInt) {
            return pageCountInt + 1
        }
        return pageCountInt
    }


    const handleCancelPrescriptionRequest = async () => {
        setOpenCancelDialog(false);
        setLoading(true)
        try {
            await cancelPrescriptionByUser(selectedPrescriptionId)
            toast.success("درخواست شما با موفقیت لغو شد");
            await fetchData();
        } catch (error) {
            toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Grid container spacing={1}>
                <Grid size={12}>
                    <Paper sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Tabs value={status} onChange={handleChange} visibleScrollbar scrollButtons variant="scrollable">
                            <Tab label='در انتظار تایید' value={prescriptionStatus.WAITING} icon={(<AccessTime />)} />
                            <Tab label='در حال پردازش' value={prescriptionStatus.PROCESSING} icon={(<Update />)} />
                            <Tab label='به اتمام رسیده' value={prescriptionStatus.FINISHED} icon={(<TaskAlt />)} />
                            <Tab label='لغو شده' value={prescriptionStatus.CANCELED} icon={(<HighlightOff />)} />
                        </Tabs>
                    </Paper>
                </Grid>

                {data?.results.map(p => (
                    <Grid size={{ xs: 12, md: 6 }}>
                        <PrescriptionCard prescription={p} key={p.id} handleCancelButtonClicked={() => {
                              setOpenCancelDialog(true);
                              setSelectedPrescriptionId(p.id);
                          }} />
                    </Grid>
                ))}

                <Grid size={12}>
                    <Paper sx={{ padding: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <Pagination color="primary" count={getPageCounts()} defaultPage={Number.parseInt(searchParams.get('page') ?? '1')} size="large" shape="rounded" onChange={handlePageChange} />
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
            title={'لغو درخواست ثبت نسخه'}
            msg={'آیا مطمئن هستید که می‌خواهید این عملیات را انجام دهید؟'}
            open={openCancelDialog} loading={loading} handleAccept={handleCancelPrescriptionRequest} handleClose={() => setOpenCancelDialog(false)} />
        </>
    )
}

export default PrescriptionsRoute
export class ConsultationCreateDTO {
    constructor(
        public consultant?: string,
        public consultation_type?: string,
        public specialty?: string,
        public discount_code?: string,
        public start_time?: string,
        public consultation_code?: string,
    ) {
    }
}

export class ConsultationCreateResponseDTO {
    constructor(
        public id: number,
        public consultant: string,
        public consultation_type: string,
        public specialty: string,
        public discount_code: string,
        public start_time: string,
        public consultation_code: string,
    ) {
    }
}
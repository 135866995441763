export class TimeLineDTO {
    constructor(
        public id: number | undefined,
        public day: "sa" | "su" | "m" | "tu" | "w" | "th" | "f",
        public time_from: string,
        public time_to: string,
        public duration: "15" | "30" | "45" | "60"
    ) {
    }


    public getDay() {
        switch (this.day) {
            case "sa":
                return "شنبه"
            case "su":
                return "یک‌شنبه"
            case "m":
                return "دو‌شنبه"
            case "tu":
                return "سه‌شنبه"
            case "w":
                return "چهار‌شنبه"
            case "th":
                return "پنج‌شنبه"
            case "f":
                return "جمعه"
        }
    }

}
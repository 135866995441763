import { Paper } from "@mui/material";
import { RegisterForm } from "../../components";
import { RegisterFormValues } from "../../components/RegisterForm/RegisterForm";
import { UpdateAccountDTO } from "../../../DTOs/account/updateAccountDTO";
import { AppDispatch } from "../../../app/store";
import { saveAccountInfoActionCreator, updateAccountInfo } from "../../../services/accountService";
import { useDispatch } from "react-redux";
import { format } from "date-fns-jalali";

import { useState } from "react";
import { toast } from "react-toastify";

const EditAccountInfoRoute = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();



    const handleSubmit = async (values: RegisterFormValues) => {
        setLoading(true);
        const birthday = format(values.birthday!!, "yyyy-MM-dd");

        try {
            const payload = new UpdateAccountDTO(values.firstName, values.lastName, values.city, birthday, values.gender);
            const result = await updateAccountInfo(payload);
            const { data } = result;
            dispatch(saveAccountInfoActionCreator(data))
            toast.success("اطلاعات شما با موفقیت ویرایش شد");

        } catch (error) {
            toast.error("عملیات با خطا مواجه شد لطفا مجدد تلاش کنید");

        } finally {
            setLoading(false);
        }
    }

    return (
        <Paper sx={{ p: 4 }}>
            <RegisterForm handleSubmitProp={handleSubmit} buttonText="ویرایش" headerText="ویرایش حساب کاربری" showLogo={false} loading={loading} />
        </Paper>
    );
}

export default EditAccountInfoRoute;
import { InputAdornment, InputBase, InputBaseProps } from "@mui/material";
import styles from './searchfield.module.css';
import SearchIcon from '@mui/icons-material/Search';
import { grey } from '@mui/material/colors';
import { useState } from "react";

interface SearchFieldProps extends InputBaseProps { }


const SearchField = (props: SearchFieldProps) => {
    return (
        <InputBase {...props} className={styles.searchfield}
            sx={{
                backgroundColor: grey[300],
                "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: "0",
                    left: "50%",
                    width: "0%",
                    height: "2px",
                    borderRadius: 10,
                    backgroundColor: "primary.main",
                    transition: "width 0.3s ease-in-out, left 0.3s ease-in-out",
                },
                "&:focus-within::after": {
                    width: "100%",
                    left: "0",
                },
            }}
            startAdornment={(
                <InputAdornment position={'start'}>
                    <SearchIcon />
                </InputAdornment>
            )}
        />
    )
}

export default SearchField;
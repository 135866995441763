import { Star } from "@mui/icons-material";
import { Avatar, Box, Divider, Typography } from "@mui/material";
import { CommentDTO } from "../../../DTOs/consultant/CommentDTO";

interface CommentPropTypes {
    comment: CommentDTO
}

const CommentCard = ({comment}: CommentPropTypes) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, justifyContent: 'center', gap: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Avatar src={comment.account.image} sx={{ width: '65px', height: '65px' }} />
                    <Box>
                        <Typography fontFamily={"Kalameh"}>{comment.account.first_name} {comment.account.last_name}</Typography>
                        <Typography variant={"caption"}>{comment.created_at}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{comment.rating}</Typography>
                    <Star color="warning" />
                </Box>
            </Box>
            <Typography variant="body1">{comment.text}</Typography>
            <Divider sx={{ mt: 1, mb: 1 }} />
        </Box>
    )
}

export default CommentCard;
import axiosInstance from "../http/axiosConfig";
import {PaginatedDTO} from "../DTOs/PaginatedDTO";
import {LoginSessionDTO} from "../DTOs/auth/LoginSessionDTO";

export const getActiveSessions = () => {
    return axiosInstance.get<PaginatedDTO<LoginSessionDTO>>("account/active-sessions/")
}

export const inactiveSession = (sessionId: number) => {
    return axiosInstance.delete(`account/active-sessions/${sessionId}/inactive`)
}
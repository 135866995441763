import { Box, CircularProgress, Grid2 as Grid, MenuItem, Paper, TextField, Typography } from "@mui/material";
import ListHeader from "../ListHeader/ListHeader";
import { useEffect, useState } from "react";
import { getConsultantTimelines } from "../../../services/consultantService";
import { TimelinesDTO } from "../../../DTOs/consultant/TimelinesDTO";
import { Jalali } from 'jalali-ts';
import Slider from "../Slider/Slider";
import { SwiperSlide } from "swiper/react";
import Button from "../Button/Button";
import Dialog from "../Dialog/Dialog";
import { checkDiscounts } from "../../../services/discountService";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { createConsultation } from "../../../services/consultationService";
import { ConsultationCreateDTO } from "../../../DTOs/consultation/ConsultationCreateDTO";


interface CreateVisitTimeSectionSectionProps {
    consultantId: string,
    specialty: string,
    tc: boolean,
    voc: boolean,
    vic: boolean,
}

const weekdays = [
    { name: 'یک‌شنبه', key: 'su' },
    { name: 'دو‌شنبه', key: 'm' },
    { name: 'سه‌شنبه', key: 'tu' },
    { name: 'چهار‌شنبه', key: 'w' },
    { name: 'پنج‌شنبه', key: 'th' },
    { name: 'جمعه', key: 'f' },
    { name: 'شنبه', key: 'sa' },
];

interface SubmitConsultationRequestValues {
    consultationType: string,
    discountCode: string,
}

const SubmitConsultationValidation = Yup.object().shape({
    consultationType: Yup.string().required("لطفا نوع مشاوره درخواستی خود را وارد کنید"),
    discountCode: Yup.string().optional()
})

const initialValues = {
    consultationType: '',
    discountCode: '',
}

const CreateVisitTimeSection = ({ consultantId, tc, voc, vic, specialty }: CreateVisitTimeSectionSectionProps) => {
    const [price, setPrice] = useState(120);
    const [loadingCheckDiscount, setLoadingCheckDiscount] = useState(false);

    const [timelines, setTimelines] = useState<TimelinesDTO>();
    const [showReserveDialog, setShowReserveDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    const formik = useFormik({
        initialValues,
        validationSchema: SubmitConsultationValidation,
        onSubmit: values => handleSubmit(values),
    })

    const [selectedDate, setSelectedDate] = useState<{
        jyear: number,
        jday: number,
        jmonth: number,
        name: string,
        dayKey: string,
        monthName: string,
    }>();
    const [selectedTime, setSelectedTime] = useState<string>();

    const fetchConsultantTimelines = async () => {
        setLoading(true)
        const response = await getConsultantTimelines(Number.parseInt(consultantId));
        setTimelines(response.data);
        setLoading(false)
    }

    const getJalaliMonthName = (monthNumber: number): string => {
        const jalaliMonths = [
            "فروردین",
            "اردیبهشت",
            "خرداد",
            "تیر",
            "مرداد",
            "شهریور",
            "مهر",
            "آبان",
            "آذر",
            "دی",
            "بهمن",
            "اسفند",
        ];

        // Validate input
        if (monthNumber < 1 || monthNumber > 12) {
            return "شماره ماه نامعتبر است.";
        }

        // Return the corresponding month name
        return jalaliMonths[monthNumber - 1];
    }

    const getWeekdays = (weeks = 2) => {
        let jtoday = Jalali.now();
        let todayIndex = new Date().getDay();
        const result: {
            jyear: number,
            jmonth: number,
            jday: number,
            name: string,
            dayKey: string,
            monthName: string,
        }[] = [];
        while (result.length < weeks * 7) {
            if (timelines!![weekdays[todayIndex].key as keyof TimelinesDTO] !== undefined) {
                result.push({
                    name: weekdays[todayIndex].name,
                    jyear: jtoday.getFullYear(),
                    jday: jtoday.getDate(),
                    jmonth: jtoday.getMonth() + 1,
                    dayKey: weekdays[todayIndex].key,
                    monthName: getJalaliMonthName(jtoday.getMonth() + 1)
                })
            }
            todayIndex += 1;
            jtoday = jtoday.add(1, 'day');
            if (todayIndex >= 7) {
                todayIndex = 0;
            }
        }
        return result;
    };

    const getTimeLineBgColor = (weekDay: any) => {
        return (selectedDate?.jday!! + selectedDate?.jmonth!! + selectedDate?.jyear!!) === (weekDay.jday + weekDay.jmonth + weekDay.jyear) ? 'primary.container' : 'background.default'
    }

    const getTimesStyle = (timeline: any) => {
        if (selectedTime === timeline) {
            return { borderRadius: '10px', bgcolor: 'primary.container', p: 1, textAlign: 'center', borderStyle: 'solid', borderWidth: 1, borderColor: 'primary.container' }
        } else {
            return { borderRadius: '10px', bgcolor: 'background.paper', p: 1, textAlign: 'center', borderStyle: 'solid', borderWidth: 1, borderColor: 'primary.container' }
        }

    }


    const handleCheckDiscountCode = async () => {
        if (formik.values.discountCode) {
            setLoadingCheckDiscount(true);
            setPrice(120);
            try {
                const result = await checkDiscounts(formik.values.discountCode);
                if (result.data.valid) {
                    setPrice((100 - result.data.percentage) * price / 100)
                    toast.success("کد تخفیف شما اعمال شد");
                } else {
                    toast.error("کد تخیفیف نامعتبر است");

                }
            } catch (error) {
                toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
            } finally {
                setLoadingCheckDiscount(false);
            }
        }
    }

    const handleSubmit = async (values: SubmitConsultationRequestValues) => {
        if (selectedDate && selectedTime) {
            setLoading(true);
            try {
                const startTime = `${selectedDate.jyear}-${selectedDate.jmonth}-${selectedDate.jday} ${selectedTime}`
                const payload = new ConsultationCreateDTO(consultantId, values.consultationType, specialty, values.discountCode, startTime);
                await createConsultation(payload);
                toast.success("درخواست شما ثبت شد");
                setShowReserveDialog(false);
            } catch (e: any) {
                if (e.response.data && e.response.data.balance) {
                    toast.error(e.response.data.balance);
                } else if (e.response.data && e.response.data.start_time) {
                    toast.error(e.response.data.start_time[0]);
                }

            } finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchConsultantTimelines();
    }, [])
    return (
        <>
            <Paper sx={{ pl: 2, pr: 2, pb: 2 }}>
                <Box>
                    <ListHeader title="دریافت نوبت" />

                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress sx={{ display: loading ? 'block' : 'none' }} />
                        </Box>

                    ) : (
                        <>
                            <Slider sxPerView={2.1} mdPerView={3.1} xlPerView={4.1} autoplay={false}>
                                {getWeekdays().map(weekDay => (
                                    <SwiperSlide onClick={() => setSelectedDate(weekDay)}>
                                        <Paper sx={{ p: 2, textAlign: 'center', bgcolor: getTimeLineBgColor(weekDay) }}>
                                            <Typography variant="caption">{weekDay.name}</Typography>
                                            <Typography >{weekDay.jday} {weekDay.monthName}</Typography>
                                        </Paper>
                                    </SwiperSlide>
                                ))}
                            </Slider>

                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                {timelines!![selectedDate?.dayKey as keyof TimelinesDTO] && timelines!![selectedDate?.dayKey as keyof TimelinesDTO].map(timeline => (
                                    <Grid size={3}>
                                        <Box sx={getTimesStyle(timeline)} onClick={() => setSelectedTime(timeline)}>
                                            <Typography>{timeline}</Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                            {selectedDate && selectedTime && (
                                <Button fullWidth sx={{ mt: 1 }} variant="contained" onClick={() => setShowReserveDialog(true)}>ثبت زمان مشاوره</Button>
                            )}
                        </>
                    )}

                </Box>
            </Paper>

            <Dialog open={showReserveDialog} title="دریافت نوبت" msg="لطفا نوع مشاوره خود را انتخاب کنید"
                loading={loading} handleAccept={formik.submitForm} handleClose={() => setShowReserveDialog(false)}>

                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <TextField label='نوع مشاوره'  select
                        name="consultationType"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.consultationType && Boolean(formik.errors.consultationType)}
                        helperText={formik.touched.consultationType && formik.errors.consultationType}
                        fullWidth>
                            {tc && (
                                <MenuItem key={'t'} value={'t'}>مشاوره متنی</MenuItem>
                            )}

                            {voc && (
                                <MenuItem key={'vo'} value={'vo'}>مشاوره صوتی</MenuItem>
                            )}

                            {vic && (
                                <MenuItem key={'vi'} value={'vi'}>مشاوره تصویری</MenuItem>
                            )}

                        </TextField>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <TextField label="کد تخفیف"
                                name="discountCode"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                sx={{ flex: 1 }} />
                            <Button variant="outlined" onClick={handleCheckDiscountCode} loading={loadingCheckDiscount}>اعمال کن</Button>
                        </Box>
                    </Box>
                </form>
                <Typography variant="caption">توجه: هزینه ثبت نوبت <Typography variant="caption" color="primary">{price} هزار تومان
                </Typography> می‌باشد از کافی بودن موجودی کیف پول خود اطمینان حاصل کنید</Typography>
                <br />

                <Typography variant={'caption'}>در صورت لغو درخواست توسط شما یا مشاور مبلغ پرداختی به کیف پول شما عودت داده
                    می‌شود</Typography>
            </Dialog>
        </>
    )
}

export default CreateVisitTimeSection;
import { useState } from "react";
import { Typography, Button, Collapse, IconButton, Box } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

interface ExpandableTextProps {
    text: string,
    maxLength?: number,
}

const ExpandableText = ({ text, maxLength = 100 }: ExpandableTextProps) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => setExpanded((prev) => !prev);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Collapse in={expanded} collapsedSize={'50px'}>
                <Typography variant="body1" component={'pre'}>{text}</Typography>
            </Collapse>
            <Box sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <IconButton onClick={toggleExpanded}>
                    {!expanded ? (<KeyboardArrowDown />) : (<KeyboardArrowUp />)}
                </IconButton>
            </Box>
        </Box>
    );
};

export default ExpandableText;

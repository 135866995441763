import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AccountSliceStateType } from "./accountSliceStateType";
import { clearToken } from "../../services/tokenStorageService";
import { getAccountInfo } from '../../services/accountService';

const initialState: AccountSliceStateType = {
    isLoggedIn: false,
    account: undefined,
    isLoading: true,
}

export const fetchAccountData = createAsyncThunk('account/fetchData', async () => {
    const response = await getAccountInfo();
    return response.data;
})

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccountInformation: (state, action) => {
            state.isLoggedIn = true;
            state.account = action.payload.account;
            return state;
        },
        logout: (state) => {
            clearToken();
            state.isLoggedIn = false;
            state.account = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccountData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAccountData.fulfilled, (state, action) => {
                state.account = action.payload;
                state.isLoading = false;
                state.isLoggedIn = true;
            })
            .addCase(fetchAccountData.rejected, (state) => {
                state.account = undefined;
                state.isLoading = false;
                state.isLoggedIn = false;
            })
    }
})

export const { setAccountInformation, logout } = accountSlice.actions
export default accountSlice.reducer;
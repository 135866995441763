import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BottomNav, Button, Footer, Header, IOSSwitch } from "../components";
import { Avatar, Box, Container, Divider, Grid2 as Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Person, AccountBalanceWallet, Percent, MedicationLiquid, Devices, Logout, SupportAgent } from "@mui/icons-material";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useState } from "react";
import { logout } from "../../features/account/accountSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { updateConsultantOnlineStatus } from "../../services/consultantService";



const AccountLayout = () => {
    const { account } = useAppSelector((state) => state.account);
    const dispatch = useDispatch<AppDispatch>();
    const { pathname } = useLocation();
    const [path, setPath] = useState(pathname);
    const [isOnline, setIsOnline] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setPath(newValue);
        navigate(newValue);
        console.log(newValue);

    };

    const handleLogout = async () => {
        navigate('/');
        dispatch(logout())
    }

    const handleIsOnlineToggle = async (event: any, checked: boolean) => {
        setIsOnline((prev) => checked);
        await updateConsultantOnlineStatus(checked);
    }

    return (
        <>
            <Header />

            <Container sx={{ pb: 9, pt: 1 }}>
                <Grid container spacing={4}>
                    <Grid size={{ xs: 12, sm: 12, md: 4, lg: 3 }}>
                        <Paper sx={{ p: 4 }}>
                            <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Avatar src={account?.image} alt="profile" sx={{ width: '85px', height: '85px' }} />
                                <Typography fontFamily={'Kalameh'}>{account?.first_name} {account?.last_name} </Typography>
                                <Typography>{account?.phone_number} </Typography>
                            </Box>
                            <Divider sx={{ mt: 1 }}>پنل کاربری</Divider>

                            <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 1 }}>
                                {account?.role === 'consultant' && (
                                    <Box sx={{ width: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
                                        <Typography fontFamily={'Kalameh'}>آنلاین هستم</Typography>
                                        <IOSSwitch onChange={handleIsOnlineToggle} checked={isOnline} />
                                    </Box>
                                )}
                                <Tabs value={path} orientation={'vertical'} onChange={handleChange} sx={{ width: '100%' }} TabIndicatorProps={{ sx: { left: 0, width: '1px', borderRadius: '10px' } }}>
                                    <Tab sx={{ justifyContent: 'start' }} label="اطلاعات حساب کاربری" value={'/account'} icon={<Person />} />
                                    <Tab sx={{ justifyContent: 'start' }} label="کیف پول" value={'/account/wallet'} icon={<AccountBalanceWallet />} />
                                    <Tab sx={{ justifyContent: 'start' }} label="مشاوره" value={'/account/consultations'} icon={<SupportAgent />} />
                                    <Tab sx={{ justifyContent: 'start' }} label="تجویز نسخه" value={'/account/prescriptions'} icon={<MedicationLiquid />} />
                                    <Tab sx={{ justifyContent: 'start' }} label="کدهای تخفیف" value={'/account/discounts'} icon={<Percent />} />
                                    <Tab sx={{ justifyContent: 'start' }} label="نشست های فعال" value={'/account/active-sessions'} icon={<Devices />} />
                                    {account?.role === 'consultant' && (
                                        <Divider>مخصوص مشاورین</Divider>
                                    )}
                                    {account?.role === 'consultant' && (
                                        <Tab sx={{ justifyContent: 'start' }} label="ویرایش اطلاعات (مشاورین)" value={'/membership'} icon={<Person />} />
                                    )}
                                    {account?.role === 'consultant' && (
                                        <Tab sx={{ justifyContent: 'start' }} label="مشاوره (مشاورین)" value={'/consultant/consultations'} icon={<SupportAgent />} />
                                    )}

                                    {account?.role === 'consultant' && (
                                        <Tab sx={{ justifyContent: 'start' }} label="تجویز نسخه (مشاورین)" value={'/consultant/prescriptions'} icon={<MedicationLiquid />} />
                                    )}
                                </Tabs>

                            </Box>
                            <Divider sx={{ mt: 2 }} />
                            <Button fullWidth
                                onClick={handleLogout}
                                startIcon={(<Logout />)}
                                color="error">
                                خروج از حساب کاربری
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 8, xl: 9 }}>
                        <Outlet />
                    </Grid>
                </Grid>
            </Container>

            <BottomNav />

            <Footer />
        </>
    )
}

export default AccountLayout;
import { Avatar, Box, Divider, Paper, Typography } from "@mui/material"
import Button from "../Button/Button"
import { PrescriptionConsultantDTO } from "../../../DTOs/prescriptions/PrescriptionConsultantDTO"

interface PrescriptionCardPropTypes {
    prescription: PrescriptionConsultantDTO,
    handleAcceptButtonClicked?: () => void,
    handleSetResultButtonClicked?: () => void

}

const PrescriptionCard = ({ prescription, handleAcceptButtonClicked, handleSetResultButtonClicked }: PrescriptionCardPropTypes) => {
    return (
        <Paper sx={{ p: 2 }}>
            {prescription.account && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Avatar alt={`${prescription.account.first_name} ${prescription.account.last_name}`}
                            src={prescription.account.image}
                        />
                        <Box>
                            <Typography>{prescription.account.first_name} {prescription.account.last_name}</Typography>
                            <Typography variant="caption">{prescription.specialty.title}</Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant={'caption'}>کد ملی ارسال شده: {prescription.national_code}</Typography>
                <Typography variant={'caption'}>تاریخ درخواست: {prescription.created_at}</Typography>
                <Typography variant={'caption'}>توضیحات: {prescription.description}</Typography>
            </Box>



            <Box sx={{mt: 2}}>
                {prescription.status === 'w' && (
                    <Button fullWidth variant="outlined" onClick={handleAcceptButtonClicked}>تایید</Button>
                )}

                {prescription.status === 'p' && (
                    <Button fullWidth variant="outlined" onClick={handleSetResultButtonClicked}>ثبت نتیجه</Button>
                )}
            </Box>


            {prescription.result && (
                <>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Typography variant={'caption'}>نتیجه: {prescription.result}</Typography>
                </>
            )}
        </Paper>
    )
}

export default PrescriptionCard;
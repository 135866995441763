import { useEffect, useState } from "react";
import { PaginatedDTO } from "../../../DTOs/PaginatedDTO";
import { Box, Grid2 as Grid, MenuItem, Pagination, Paper, Tab, Tabs, TextField } from "@mui/material";
import { AccessTime, Update } from "@mui/icons-material";
import { acceptPrescriptionsForConsultant, cancelPrescriptionByUser, getProcessingPrescriptionsForConsultant, getWaitingPrescriptionsForConsultant, setResultPrescription } from "../../../services/prescriptionService";
import { useSearchParams } from "react-router-dom";
import { ConsultantPrescriptionCard, Dialog } from "../../components";
import { toast } from "react-toastify";
import { PrescriptionConsultantDTO, prescriptionConsultantStatus } from "../../../DTOs/prescriptions/PrescriptionConsultantDTO";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { PrescriptionSetResultDTO } from "../../../DTOs/prescriptions/PrescriptionSetResultDTO";


interface setResultFormValues {
    result: string,
    resultStatus: string,
}

const setResultOptions = [
    { label: 'به اتمام رسیده', value: 'f' },
    { label: 'لغو کردن', value: 'c' },
]

const setResultInitialValues = {
    result: '',
    resultStatus: '',
}

const setResultValidationScheme = Yup.object().shape({
    result: Yup.string().required("این مقداراجباری است"),
    resultStatus: Yup.string().required("این مقداراجباری است"),
})


const ConsultantPrescriptionsRoute = () => {
    const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
    const [openSetResultDialog, setOpenSetResultDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState<PaginatedDTO<PrescriptionConsultantDTO>>();
    const [loading, setLoading] = useState(false);
    const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(0);
    const [status, setStatus] = useState<prescriptionConsultantStatus>(prescriptionConsultantStatus.WAITING);

    const formik = useFormik({
        initialValues: setResultInitialValues,
        onSubmit: (values: setResultFormValues) => handleSetResultPrescriptionRequest(values),
        validationSchema: setResultValidationScheme,
    })

    const handleChange = async (event: React.SyntheticEvent, newValue: prescriptionConsultantStatus) => {
        const currentStatus = status;
        if (currentStatus === null || currentStatus !== newValue) {
            setSearchParams((prev) => {
                prev.delete('page')
                prev.append('page', '1');
                return prev
            })
            setStatus(newValue);
            await fetchData(newValue);
        }
    };


    const fetchWaitingPrescriptions = async () => {
        const response = await getWaitingPrescriptionsForConsultant(searchParams);
        setData(response.data);
    }

    const fetchProcessingPrescriptions = async () => {
        const response = await getProcessingPrescriptionsForConsultant(searchParams);
        setData(response.data);
    }

    const fetchData = async (status: prescriptionConsultantStatus = prescriptionConsultantStatus.WAITING) => {
        if (status === prescriptionConsultantStatus.WAITING) {
            fetchWaitingPrescriptions();
        } else if (status === prescriptionConsultantStatus.PROCESSING) {
            fetchProcessingPrescriptions()
        }
    }

    const handlePageChange = async (event: any, page: number) => {
        const currentPage = searchParams.get('page') ?? '1';
        if (currentPage === null || currentPage !== page.toString()) {
            setSearchParams((prev) => {
                prev.delete('page')
                prev.append('page', page.toString());
                return prev
            })
            await fetchData();
        }
    }

    const getPageCounts = () => {
        const pageCount = (data?.count ?? 0) / 25;

        const pageCountInt = Number.parseInt(pageCount.toString());

        if (pageCountInt === 0) {
            return 1
        }
        if (pageCount > pageCountInt) {
            return pageCountInt + 1
        }
        return pageCountInt
    }


    const handleAcceptPrescriptionRequest = async () => {
        setLoading(true);
        try {
            const result = await acceptPrescriptionsForConsultant(selectedPrescriptionId);
            if (result.status === 200) {
                toast.success("درخواست کاربر را قبول کردید");
                setOpenAcceptDialog(false);
                await fetchData(status);
            }
        }
        catch (e) {
            toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
        } finally {
            setLoading(false)
        }
    }

    const handleSetResultPrescriptionRequest = async (values: setResultFormValues) => {
        setLoading(true);
        try {
            const { result, resultStatus } = values;
            const response = await setResultPrescription(selectedPrescriptionId, new PrescriptionSetResultDTO(result, resultStatus))
            if (response.status === 200) {
                toast.success("نتیجه با موفقیت ثبت شد");
                setOpenSetResultDialog(false);
                await fetchData(status);
            }
        } catch (error) {
            toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Grid container spacing={1}>
                <Grid size={12}>
                    <Paper sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Tabs value={status} onChange={handleChange} visibleScrollbar scrollButtons variant="scrollable">
                            <Tab label='در انتظار تایید' value={prescriptionConsultantStatus.WAITING} icon={(<AccessTime />)} />
                            <Tab label='در حال پردازش' value={prescriptionConsultantStatus.PROCESSING} icon={(<Update />)} />
                        </Tabs>
                    </Paper>
                </Grid>

                {data?.results.map(p => (
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ConsultantPrescriptionCard prescription={p} key={p.id} handleAcceptButtonClicked={() => {
                            setOpenAcceptDialog(true);
                            setSelectedPrescriptionId(p.id);
                        }}
                            handleSetResultButtonClicked={() => {
                                setOpenSetResultDialog(true);
                                setSelectedPrescriptionId(p.id);
                            }}
                        />
                    </Grid>
                ))}

                <Grid size={12}>
                    <Paper sx={{ padding: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <Pagination color="primary" count={getPageCounts()} defaultPage={Number.parseInt(searchParams.get('page') ?? '1')} size="large" shape="rounded" onChange={handlePageChange} />
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                title={'تایید درخواست ثبت نسخه'}
                msg={'آیا مطمئن هستید که می‌خواهید این عملیات را انجام دهید؟'}
                open={openAcceptDialog} loading={loading} handleAccept={handleAcceptPrescriptionRequest} handleClose={() => setOpenAcceptDialog(false)} />

            <Dialog
                title={'ثبت نتیجه'}
                msg={'لطفا تمامی مقادیر را وارد کنید'}
                open={openSetResultDialog} loading={loading} handleAccept={formik.submitForm} handleClose={() => setOpenSetResultDialog(false)}>

                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <TextField select
                            name="resultStatus"
                            label="وضعیت" fullWidth
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.resultStatus && Boolean(formik.errors.resultStatus)}
                            helperText={formik.touched.resultStatus && formik.errors.resultStatus}
                        >
                            {setResultOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </TextField>

                        <TextField name="result" multiline label={'نتیجه'} fullWidth
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.result && Boolean(formik.errors.result)}
                            helperText={formik.touched.result && formik.errors.result} />
                    </Box>
                </form>
            </Dialog>
        </div>
    )
}

export default ConsultantPrescriptionsRoute
import { PropsWithChildren } from "react";
import { Swiper } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';

import { Autoplay, Pagination } from "swiper/modules";

interface SliderPropTypes extends PropsWithChildren {
    sxPerView?: number,
    mdPerView?: number,
    xlPerView?: number,
    autoplay?: boolean
}

const Slider = ({ children, sxPerView = 1.1, mdPerView = 2.1, xlPerView = 4.1, autoplay = true }: SliderPropTypes) => {
    return (
        <Swiper
            className="mySwiper"
            slidesPerView={sxPerView}
            spaceBetween={10}
            breakpoints={{
                600: {
                    slidesPerView: sxPerView,
                },
                900: {
                    slidesPerView: mdPerView,
                },
                1536: {
                    slidesPerView: xlPerView,
                },
            }}
            modules={[Pagination, Autoplay]}
            autoplay={autoplay}>
            {children}
        </Swiper>
    );
};

export default Slider;

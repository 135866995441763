import { Box, Chip, Divider, Paper, Typography } from "@mui/material";
import Button from "../Button/Button";
import { DiscountDTO } from "../../../DTOs/discounts/DiscountDTO";
import { useState } from "react";
import { toast } from "react-toastify";
import Clipboard from "@react-native-clipboard/clipboard";




interface DiscountCardPropTypes {
    discount: DiscountDTO
}

const DiscountCard = ({ discount }: DiscountCardPropTypes) => {
    const [loading, setLoading] = useState(false);

    const handleCopyCode = async () => {
        setLoading(true);
        try {
            Clipboard.setString(discount.code);
            toast.success('کد تخفیف کپی شد');
        } catch (error) {
            toast.error('خطایی پیش آمده');        
        } finally {
            setLoading(false);
        }
    }

    return (
        <Paper sx={{p: 2}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography>کد تخفیف {discount.percentage} درصد</Typography>
                <Typography fontFamily={'Kalameh'} sx={{padding: '10px', borderStyle: 'dashed', borderRadius: '10px', borderColor: 'error.main'}}>{discount.code.toUpperCase()}</Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', mt: 3}}>
                <Chip label={`معتبر تا ${discount.days_remained} روز دیگر`} />
                <Chip label="یکبار مصرف" />
            </Box>
            <Divider sx={{m: 1}} />
            <Button fullWidth onClick={handleCopyCode} loading={loading}>کپی کن</Button>
        </Paper>
    )
}

export default DiscountCard;
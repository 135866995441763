import { Grid2 as Grid, Typography } from "@mui/material";
import Button from "../Button/Button";
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";

interface ListHeaderPropTypes {
    title: string,
    showButton?: boolean,
    onClick?: () => void,
    buttonLinkTo?: string,
}

const ListHeader = ({ title, showButton = false, onClick, buttonLinkTo }: ListHeaderPropTypes) => {
    return (
        <Grid container alignItems={'center'} sx={{ pt: 2, pb: 2 }}>
            <Grid size={6} textAlign={'start'}>
                <Typography fontFamily={"Kalameh"} variant="h6">{title}</Typography>
            </Grid>
            <Grid size={6} textAlign={'end'} display={(showButton && buttonLinkTo) ? 'block' : 'none'}>
                <Link to={buttonLinkTo!!}>
                    <Button endIcon={(<ArrowBack />)} onClick={onClick}>مشاهده همه</Button>
                </Link>
            </Grid>
        </Grid>
    )
}

export default ListHeader;
import { Box, Container, Paper, Stack, Typography, Link, IconButton } from "@mui/material";

import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {

    const SOCIAL_NETWORKS = [
        { icon: <EmailIcon />, link: "mailto:Doctoreman1025@gmail.com" },
        { icon: <TelegramIcon />, link: "https://t.me/doctoreman1" },
        { icon: <InstagramIcon />, link: "https://www.instagram.com/doctoremann?igsh=YjZtN2pxb3BrdW9l" },
    ]

    return (
        <Paper>
            <Box
                component="footer"
                sx={{
                    backgroundColor: "primary.main",
                    color: "white",
                    py: 1.5,
                    bottom: 0,
                    position: 'static',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <Container>
                    <Typography variant="body2">
                        دکتر همیشه باهاته :)
                    </Typography>
                </Container>
                <Container>
                    <Typography fontFamily={"Kalameh"}>
                        دکترمن
                    </Typography>
                </Container>
                <Container>
                    {/* Social Media Links */}
                    <Stack direction="row" justifyContent="center" spacing={2}>
                        {SOCIAL_NETWORKS.map((item, index) => (
                            <Link key={index} href={item.link} target="_blank">
                                <IconButton
                                    sx={{
                                        color: "white",
                                    }}
                                >
                                    {item.icon}
                                </IconButton>
                            </Link>
                        ))}
                    </Stack>
                </Container>
            </Box>
        </Paper>
    )
}

export default Footer;
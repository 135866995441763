import { Autocomplete, Box, Grid2 as Grid, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { SpecialtyDTO } from "../../../../DTOs/specialty/SpecialtyDTO";
import { getSpecialties } from "../../../../services/specialtyService";
import { Button, IOSSwitch } from "../../../components";
import { toast } from "react-toastify";

import { getConsultantInfo } from "../../../../services/consultantService";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { Link } from "react-router-dom";

const consultantAdditionalInformationRegisterValidation = Yup.object().shape({
    nationalCode: Yup.number().typeError("لطفا تنها از اعداد انگلیسی استفاده کنید").required("این مقدار اجباری است"),
    medicalEducationNumber: Yup.number().typeError("لطفا تنها از اعداد انگلیسی استفاده کنید").required("این مقدار اجباری است"),
    specialty: Yup.number().required("این مقدار اجباری است"),
    consultantImage: Yup.string().required("بارگذاری تصویر از خودتان اجباری است"),
    consultantDegreeImage: Yup.string().required("بارگذاری تصویر مدرک اجباری است"),
    officeAddress: Yup.string().optional(),
    isTextConsultation: Yup.boolean().optional(),
    isVoiceConsultation: Yup.boolean().optional(),
    isVideoConsultation: Yup.boolean().optional(),
    bio: Yup.string().optional(),
})

const initialValues = {
    nationalCode: '',
    medicalEducationNumber: '',
    specialty: undefined,
    officeAddress: '',
    bio: '',
    consultantDegreeImage: '',
    consultantImage: '',
    isTextConsultation: false,
    isVoiceConsultation: false,
    isVideoConsultation: false
}

export interface ConsultantAdditionalInformationFormValues {
    nationalCode: string
    medicalEducationNumber: string
    specialty?: number
    consultantImage: string
    consultantDegreeImage: string
    officeAddress: string
    bio: string,
    isTextConsultation: boolean,
    isVoiceConsultation: boolean,
    isVideoConsultation: boolean,
}

interface Step2PropsType {
    handleSubmitProps: (values: ConsultantAdditionalInformationFormValues) => void;
    loading: boolean,
    loadData: boolean
}


// TODO: load data
const Step2 = (props: Step2PropsType) => {
    const { account } = useAppSelector((state) => state.account);
    const [specialties, setSpecialties] = useState<SpecialtyDTO[]>([]);

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => props.handleSubmitProps(values),
        validationSchema: consultantAdditionalInformationRegisterValidation,
    })

    const handlePickImage = async (event: any, fieldName: string) => {
        const file = event.currentTarget.files[0];
        console.log(file);

        if (file && file.type.startsWith("image/")) {
            if (file.size < 5 * 1024 * 1024) {
                formik.setFieldValue(fieldName, file)
            } else {
                toast.error("فایل انتخابی نباید بیشتر از 5 مگابایت حجم داشته باشد")
            }

        } else {
            toast.error("فایل انتخابی باید عکس باشد")
        }


    }

    const getSpecialtiesAsync = async () => {
        const response = await getSpecialties();
        setSpecialties(response.data.results);
    }

    const loadDataAsync = async () => {
        const { data } = await getConsultantInfo();
        await formik.setFieldValue('consultantDegreeImage', data.degree);

        await formik.setFieldValue('consultantImage', account?.image);

        await formik.setFieldValue('nationalCode', data.national_code.toString());
        await formik.setFieldValue('medicalEducationNumber', data.medical_education_number.toString());

        await formik.setFieldValue('specialty', data.specialty.id.toString());


        await formik.setFieldValue('officeAddress', data.office_address);
        await formik.setFieldValue('isTextConsultation', data.is_text_consultation);
        await formik.setFieldValue('isVoiceConsultation', data.is_voice_consultation);
        await formik.setFieldValue('isVideoConsultation', data.is_video_consultation);
        await formik.setFieldValue('officeAddress', data.office_address);
        await formik.setFieldValue('officeAddress', data.office_address);
        await formik.setFieldValue('bio', data.bio);
    }

    useEffect(() => {
        const fetchData = async () => {
            await getSpecialtiesAsync();
            if (props.loadData) {
                await loadDataAsync()
            }
        }
        fetchData();
    }, [])


    return (
        <Paper sx={{ p: 3 }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>

                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField helperText={'تصویر شما'}
                            error={formik.touched.consultantImage && Boolean(formik.errors.consultantImage)}
                            name="consultantImage" onChange={(e) => handlePickImage(e, 'consultantImage')} onBlur={formik.handleBlur} type="file" fullWidth />
                        {formik.values.consultantImage && (
                            <Link style={{ textDecoration: 'none' }} to={formik.values.consultantImage} target="_blank">تصویر فعلی</Link>
                        )}
                    </Grid>

                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField helperText={'تصویر مدرک'}
                            error={formik.touched.consultantDegreeImage && Boolean(formik.errors.consultantDegreeImage)}
                            name="consultantDegreeImage" onChange={(e) => handlePickImage(e, 'consultantDegreeImage')} onBlur={formik.handleBlur} type="file" fullWidth />
                        {formik.values.consultantDegreeImage && (
                            <Link style={{ textDecoration: 'none' }} to={formik.values.consultantDegreeImage} target="_blank">تصویر فعلی</Link>
                        )}

                    </Grid>

                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField label={'کد ملی'} name="nationalCode"
                            value={formik.values.nationalCode}
                            helperText={formik.touched.nationalCode && formik.errors.nationalCode}
                            error={formik.touched.nationalCode && Boolean(formik.errors.nationalCode)}
                            onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth />
                    </Grid>

                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField label={'شماره نظام پزشکی'} name="medicalEducationNumber"
                            value={formik.values.medicalEducationNumber}
                            helperText={formik.touched.medicalEducationNumber && formik.errors.medicalEducationNumber}
                            error={formik.touched.medicalEducationNumber && Boolean(formik.errors.medicalEducationNumber)}
                            onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth />
                    </Grid>

                    <Grid size={{ xs: 12, md: 6 }}>
                        <Autocomplete
                            options={specialties}
                            getOptionLabel={(option) => option.title}
                            onChange={(event, newValues) => formik.setFieldValue('specialty', newValues?.id)}
                            onBlur={formik.handleBlur}
                            renderInput={(params) => (
                                <TextField label={'تخصص'} name="specialty" {...params} helperText={formik.touched.specialty && formik.errors.specialty} error={formik.touched.specialty && Boolean(formik.errors.specialty)} />
                            )}
                        />
                    </Grid>

                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField label={'آدرس مطب (اختیاری)'} multiline name="officeAddress"
                            value={formik.values.officeAddress}
                            onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth />
                    </Grid>

                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField label={'بیوگرافی (اختیاری)'} multiline name="bio"
                            value={formik.values.bio}
                            onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth />
                    </Grid>

                    <Box sx={{ width: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
                        <Typography fontFamily={'Kalameh'}>مشاوره متنی</Typography>
                        <IOSSwitch checked={formik.values.isTextConsultation} onChange={(e, checked) => formik.setFieldValue('isTextConsultation', checked)} />

                    </Box>

                    <Box sx={{ width: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
                        <Typography fontFamily={'Kalameh'}>مشاوره صوتی</Typography>
                        <IOSSwitch checked={formik.values.isVoiceConsultation} onChange={(e, checked) => formik.setFieldValue('isVoiceConsultation', checked)} />
                    </Box>

                    <Box sx={{ width: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
                        <Typography fontFamily={'Kalameh'}>مشاوره تصویری</Typography>
                        <IOSSwitch checked={formik.values.isVideoConsultation} onChange={(e, checked) => formik.setFieldValue('isVideoConsultation', checked)} />
                    </Box>

                    <Grid size={{ xs: 12, md: 6 }}>
                        <Button variant="contained" fullWidth onClick={formik.submitForm} loading={props.loading}>ثبت</Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}

export default Step2;
import { useEffect, useRef, useState } from "react";
import { getToken } from "../../../services/tokenStorageService";
import { Avatar, Box, Container, Grid2 as Grid, IconButton, Paper, Typography } from "@mui/material";
import { Button, Dialog, ProtectedBlur } from "../../components";
import { Call, LocalSee, Mic, MicOff, PhoneDisabled } from "@mui/icons-material";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom"

const BASE_URL = process.env.REACT_APP_BASE_URL;;

const SIGNALING_SERVER_URL = process.env.REACT_APP_WS_URL;

type OtherUser = {
    first_name: string,
    last_name: string,
    img: string,
}

type SignalingMessage = {
    type: "offer" | "answer" | "ice-candidate" | 'data' | 'close_consultation' | 'close_consultation_request';
    offer?: RTCSessionDescriptionInit;
    answer?: RTCSessionDescriptionInit;
    candidate?: RTCIceCandidateInit;
    first_name: string,
    last_name: string,
    img: string,
};

const VoiceChatRoute = () => {
    const { consultationCode } = useParams();
    const peerRef = useRef<RTCPeerConnection | null>(null);
    const wsRef = useRef<WebSocket | null>(null);
    const [localStream, setLocalStream] = useState<MediaStream | null>(null);
    const [otherUser, setOtherUser] = useState<OtherUser>();
    const { account } = useAppSelector((state) => state.account);
    const [isConnected, setIsConnected] = useState(false);
    const [isOfferCreated, setIsOfferCreated] = useState(false);
    const [micOn, setMicOn] = useState(true);

    const navigate = useNavigate()

    const [showCloseConsultationDialog, setShowCloseConsultationDialog] = useState(false);



    useEffect(() => {
        wsRef.current = new WebSocket(`${SIGNALING_SERVER_URL}signaling/${consultationCode}/?token=${getToken()}`);
        wsRef.current.onopen = () => console.log("Connected to signaling server");
        wsRef.current.onmessage = handleSignalingMessage;

        navigator.mediaDevices
            .getUserMedia({ video: false, audio: true })
            .then((stream) => {
                setLocalStream(stream);
                initPeerConnection(stream);
            });

        return () => {
            localStream?.getTracks().forEach((track) => track.stop());
            wsRef.current?.close()
            wsRef.current = null;
            peerRef.current?.close();
            peerRef.current = null;
        };
    }, []);

    const initPeerConnection = (stream: MediaStream) => {
        peerRef.current = new RTCPeerConnection({
            iceServers: [
                { urls: 'stun:turn.doctoreman.com.com:3478' },
                { urls: 'turn:turn.doctoreman.com:3478?transport=udp', username: 'turnuser', credential: 'turn456' }
            ]
        });
        stream.getTracks().forEach((track) => peerRef.current?.addTrack(track, stream));

        peerRef.current.onicecandidate = (event) => {
            if (event.candidate && wsRef.current) {
                wsRef.current.send(JSON.stringify({ type: "ice-candidate", candidate: event.candidate }));
            }
        };

        peerRef.current.ontrack = (event) => {
            const remoteAudio = new Audio();
            remoteAudio.srcObject = event.streams[0];
            remoteAudio.play();
        };
    };

    const handleSignalingMessage = (message: MessageEvent) => {
        const data: SignalingMessage = JSON.parse(message.data);

        switch (data.type) {
            case "offer":
                handleOffer(data.offer!);
                break;
            case "answer":
                peerRef.current?.setRemoteDescription(new RTCSessionDescription(data.answer!));
                break;
            case "ice-candidate":
                peerRef.current?.addIceCandidate(new RTCIceCandidate(data.candidate!));
                break;
            case "data":
                const userData = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    img: data.img,
                }
                setOtherUser(userData);
                break;
            case "close_consultation_request":
                setShowCloseConsultationDialog(true);
                break;

            case "close_consultation":
                toast.info("اتمام جلسه");
                navigate(-1);
                break;
            default:
                break;
        }
    };

    const startCall = async () => {
        if (!peerRef.current) return;
        const offer = await peerRef.current.createOffer();
        await peerRef.current.setLocalDescription(offer);
        wsRef.current?.send(JSON.stringify({ type: "offer", offer }));
        setIsOfferCreated(true);
    };

    const handleOffer = async (offer: RTCSessionDescriptionInit) => {
        if (!peerRef.current) return;
        await peerRef.current.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await peerRef.current.createAnswer();
        await peerRef.current.setLocalDescription(answer);
        wsRef.current?.send(JSON.stringify({ type: "answer", answer }));
        setIsConnected(true);
    };

    const toggleMute = () => {
        if (localStream) {
            localStream.getAudioTracks().forEach((track) => {
                track.enabled = !track.enabled;
                setMicOn(track.enabled)
            });
        }
    }

    const closeConsultationMessage = () => {
        if (wsRef.current) {
            const newMessage = { message_type: 'closeConsultationRequest' }
            wsRef.current.send(JSON.stringify(newMessage));
            toast.success("درخواست شما برای طرف مقابل ارسال شد")
        }
    }

    const closeConsultation = () => {
        if (wsRef.current) {
            const newMessage = { message_type: 'closeConsultation' }
            wsRef.current.send(JSON.stringify(newMessage));
            toast.info("اتمام جلسه")
            navigate(-1);
        }
    }

    return (
        <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100vh', pb: 1, pt: 1 }}>
                <Grid container spacing={2} sx={{ flexGrow: 1, justifyContent: 'center', display: 'flex', width: '100%', alignItems: 'center' }}>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <ProtectedBlur title="طرف مقابل وارد گفتگو نشده است" isBlur={isConnected}>
                            <Paper sx={{ width: 1, height: '300px', display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                                <Avatar src={`${BASE_URL}${otherUser?.img}`} sx={{ width: '100px', height: '100px' }} />
                                <Typography>{otherUser?.first_name} {otherUser?.last_name}</Typography>
                            </Paper>
                        </ProtectedBlur>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Paper sx={{ width: 1, height: '300px', display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                            <Avatar src={`${account?.image}`} sx={{ width: '100px', height: '100px' }} />
                            <Typography>{account?.first_name} {account?.last_name}</Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Box>
                    <Paper sx={{ p: 3, display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                        {isOfferCreated ? (
                            <Button onClick={closeConsultationMessage} disabled={!isOfferCreated}
                                startIcon={(<PhoneDisabled />)}
                                color="error"
                                variant="contained">اتمام گفتگو</Button>
                        ) : (
                            <Button onClick={startCall} disabled={isOfferCreated}
                                startIcon={(<Call />)}
                                variant="contained">شروع گفتگو</Button>
                        )}

                        <Button onClick={toggleMute}
                            variant="contained">
                            {micOn ? (<Mic />) : (<MicOff />)}
                        </Button>
                    </Paper>
                </Box>
            </Box>

            <Dialog open={showCloseConsultationDialog} title="اتمام جلسه" msg="طرف مقابل درخواست اتمام جلسه را دارد"
                handleAccept={closeConsultation}
                handleClose={() => setShowCloseConsultationDialog(false)} />
        </Container>
    );
}


export default VoiceChatRoute;
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { LoginSessionDTO } from "../../../DTOs/auth/LoginSessionDTO";
import { Language, Delete } from '@mui/icons-material';


interface ActiveSessionCardPropTypes {
    session: LoginSessionDTO,
    onPress: () => void,
    showDeleteIcon: boolean
}

const ActiveSessionCard = ({ session, onPress, showDeleteIcon }: ActiveSessionCardPropTypes) => {
    return (
        <Paper sx={{ p: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Language color="primary" />
                <Box>
                    <Typography>دستگاه: {session.platform}</Typography>
                    <Typography>آی پی: {session.ip_address}</Typography>
                </Box>
            </Box>
            {showDeleteIcon && (
                <IconButton color="error" onClick={onPress}>
                    <Delete />
                </IconButton>
            )}
        </Paper>
    )
}

export default ActiveSessionCard;
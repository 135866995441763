import { Verified, Place, Check, Close } from "@mui/icons-material";
import { Avatar, Box, Container, Grid2 as Grid, Paper, Typography } from "@mui/material";
import ListHeader from "../../components/ListHeader/ListHeader";
import { CommentsSection, CreateVisitTimeSection, ExpandableText } from "../../components";
import { ConsultantDetailDTO } from "../../../DTOs/consultant/ConsultantDetailDTO";
import { useEffect, useState } from "react";
import { getConsultantDetail } from "../../../services/consultantService";
import { useParams } from "react-router-dom";

const ConsultantDetailRoute = () => {
    const [consultantDetail, setConsultantDetail] = useState<ConsultantDetailDTO>();
    const { consultantId } = useParams();

    const fetchConsultantDetail = async () => {
        const response = await getConsultantDetail(consultantId!!)
        setConsultantDetail(response.data);
    }

    useEffect(() => {
        fetchConsultantDetail();
    }, [])

    return (
        <>
            {consultantDetail && (
                <Container>
                    <Grid container spacing={3}>
                        <Grid size={{ xs: 12, md: 5 }}>
                            <Grid container spacing={2}>
                                <Grid size={12}>
                                    <Paper sx={{ p: 2 }}>
                                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                            <Avatar src={consultantDetail.account.image} sx={{ width: '100px', height: '100px' }} />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                <Typography variant={'h5'} fontFamily={'Kalameh'}>{consultantDetail.account.first_name} {consultantDetail.account.last_name}</Typography>
                                                <Typography>{consultantDetail.specialty.title}</Typography>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <Place />
                                                    <Typography>{consultantDetail.account.city}</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <Verified color="primary" />
                                                    <Typography>کد نظام پزشکی: {consultantDetail.medical_education_number}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ m: 1, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 1 }}>
                                            <Box sx={{ flexGrow: 1, p: 1, borderRadius: '10px', justifyContent: 'center', backgroundColor: 'success.container', display: 'flex', alignItems: 'center' }}>
                                                <Check />
                                                <Typography>مشاوره های انجام شده: {consultantDetail.general_stats.done_consultation}</Typography>
                                            </Box>
                                            <Box sx={{ flexGrow: 1, p: 1, borderRadius: '10px', justifyContent: 'center', backgroundColor: 'error.container', display: 'flex', alignItems: 'center' }}>
                                                <Close />
                                                <Typography>مشاوره های لغو شده: {consultantDetail.general_stats.canceled_consultation}</Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid size={12}>
                                    <Paper sx={{ pl: 2, pr: 2, pb: 2 }}>
                                        <ListHeader title="مشاوره های قابل" />
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                            <Box sx={{ flexGrow: 1, p: 1, borderRadius: '10px', backgroundColor: consultantDetail.is_voice_consultation ? 'primary.container' : 'error.container', display: 'flex', alignItems: 'center' }}>
                                                {consultantDetail.is_voice_consultation ? (<Check />) : (<Close />)}
                                                <Typography>مشاوره‌ی صوتی</Typography>
                                            </Box>
                                            <Box sx={{ flexGrow: 1, p: 1, borderRadius: '10px', backgroundColor: consultantDetail.is_video_consultation ? 'primary.container' : 'error.container', display: 'flex', alignItems: 'center' }}>
                                                {consultantDetail.is_video_consultation ? (<Check />) : (<Close />)}
                                                <Typography>مشاوره‌ی تصویری</Typography>
                                            </Box>
                                            <Box sx={{ flexGrow: 1, p: 1, borderRadius: '10px', backgroundColor: consultantDetail.is_text_consultation ? 'primary.container' : 'error.container', display: 'flex', alignItems: 'center' }}>
                                                {consultantDetail.is_text_consultation ? (<Check />) : (<Close />)}
                                                <Typography>مشاوره‌ی متنی</Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid size={12}>
                                    <Paper sx={{ pl: 2, pr: 2, pb: 2 }}>
                                        <ListHeader title="بیوگرافی" />
                                        <ExpandableText text={consultantDetail.bio} />
                                    </Paper>
                                </Grid>
                                {consultantDetail.office_address && (
                                    <Grid size={12}>
                                        <Paper sx={{ pl: 2, pr: 2, pb: 2 }}>
                                            <ListHeader title="آدرس مطب" />
                                            <Typography>{consultantDetail.office_address}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid size={{ xs: 12, md: 7 }} container spacing={2}>
                            <Grid size={12}>
                                <CreateVisitTimeSection consultantId={consultantId!!} tc={consultantDetail.is_text_consultation}
                                    vic={consultantDetail.is_video_consultation} voc={consultantDetail.is_video_consultation} specialty={consultantDetail.specialty.id.toString()}
                                />
                            </Grid>
                            <Grid size={12}>
                                <CommentsSection consultantId={consultantId!!} commentsCount={consultantDetail.comments_count} rating={consultantDetail.rating} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </>
    )
}

export default ConsultantDetailRoute;